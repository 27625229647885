<template>
  <div class="popup-form">
    <van-action-sheet
      v-model="curIsShow"
      :round="false"
      :title="title"
      @close="close"
    >
      <div class="content">
        <div
          v-for="(formItem, index) in formItemArr"
          :key="index"
          class="form-item"
        >
          <div class="label">{{ formItem.label }}</div>
          <div v-if="formItem.type === 'textarea'" class="select-item-wrapper">
            <van-field
              v-model="curFormFieldMap[formItem.field]"
              rows="2"
              autosize
              type="textarea"
              maxlength="1000"
              placeholder="请输入"
              show-word-limit
              style="border:1px solid #999"
              :rules="[
                {
                  required: false,
                  message: '必填'
                }
              ]"
              @change="fieldValueChange(index, curFormFieldMap[formItem.field])"
            />
          </div>
          <div v-if="formItem.type === 'radio'" class="select-item-wrapper">
            <van-radio-group
              v-model="curFormFieldMap[formItem.field]"
              direction="horizontal"
              @change="fieldValueChange(index, curFormFieldMap[formItem.field])"
            >
              <van-radio
                v-for="(item2, index2) in formItem.data"
                :key="index2"
                :name="item2.value"
                class="select-item"
              >
                {{ item2.name }}</van-radio
              >
            </van-radio-group>
          </div>
          <div v-if="formItem.type === 'checkbox'" class="select-item-wrapper">
            <van-checkbox-group
              v-model="curFormFieldMap[formItem.field]"
              direction="horizontal"
              @change="fieldValueChange(index, curFormFieldMap[formItem.field])"
            >
              <van-checkbox
                v-for="(item2, index2) in formItem.data"
                :key="index2"
                :name="item2.value"
                class="select-item"
              >
                {{ item2.name }}</van-checkbox
              >
            </van-checkbox-group>
          </div>
          <div v-if="formItem.type === 'timeRange'" class="select-item-wrapper">
            <div class="time-input-wrapper">
              <van-cell
                title="选择日期区间"
                :value="curFormFieldMap[formItem.field]"
                clearable
                @click="show = true"
              />
              <van-calendar
                v-model="show"
                clearable
                type="range"
                :show-confirm="false"
                @confirm="date => onConfirm(index, date, formItem.field)"
              />
              <span
                v-show="!!curFormFieldMap[formItem.field]"
                class="clear-btn"
                @click="onConfirm(index, [], formItem.field)"
                >x</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="btns">
        <van-button type="default" @click="reset">清空</van-button>
        <van-button color="#000" @click="sure">确定</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  name: 'PopupForm',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    formFieldMap: {
      type: Object,
      default: () => {}
    },
    formItemArr: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      curIsShow: false,
      curFormFieldMap: {},
      keyWord: '',
      type: '',
      // 日期
      date: '',
      show: false
    }
  },
  watch: {
    isShow(val) {
      this.curIsShow = this.isShow
    },
    formFieldMap: {
      handler(val) {
        console.log(val, 'formFieldMap变化')
        this.curFormFieldMap = { ...this.formFieldMap }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.curIsShow = this.isShow
    this.curFormFieldMap = { ...this.formFieldMap }
  },
  methods: {
    setFieldValue(objName, property, value) {
      this[objName][property] = value
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    onConfirm(index, date, field) {
      if (date.length === 0) {
        this.curFormFieldMap[field] = ''
        this.$emit('fieldValueChange', index, this.curFormFieldMap[field])
      }
      else {
        const [start, end] = date
        this.show = false
        this.curFormFieldMap[field] = `${this.formatDate(
          start
        )} 至 ${this.formatDate(end)}`
        this.$emit('fieldValueChange', index, this.curFormFieldMap[field])
      }
    },
    search(val) {
      console.log(val)
      this.$emit('search', this.type, this.keyWord)
    },
    typeChange(val) {
      console.log(val)
      this.keyWord = ''
      this.$emit('search', this.type, '')
    },
    close() {
      // this.curIsShow = false
      this.$emit('close')
    },
    reset() {
      // this.$emit('reset')//点击重置不直接关闭弹窗，只清空当前搜索条件
      // this.$emit('close')
      this.formItemArr.map(item => {
        if (
          item.type === 'textarea' ||
          item.type === 'radio' ||
          item.type === 'timeRange'
        ) {
          this.curFormFieldMap[item.field] = ''
        }
        else if (item.type === 'checkbox') {
          this.curFormFieldMap[item.field] = []
        }
      })
    },
    sure() {
      this.$emit('sure', { ...this.curFormFieldMap })
      this.$emit('close')
    },
    fieldValueChange(index, resultVal) {
      this.$emit('fieldValueChange', index, resultVal)
    }
  }
}
</script>

<style scoped lang="less">
.popup-form {
  /deep/.van-action-sheet__header {
    padding: 0 15px;
    text-align: left;
    .van-icon {
      color: #050404;
    }
  }
  /deep/.van-action-sheet__content {
    padding: 15px;
    .content {
      padding-bottom: 100px;
      .form-item {
        margin-bottom: 20px;
        .label {
          font-family: DFPKingGothicGB-Regular;
          font-size: 14px;
          color: #050404;
          text-align: left;
          line-height: 24px;
          margin-bottom: 15px;
        }
        .select-item-wrapper {
          .select-item {
            margin-bottom: 20px;
          }
          .van-radio-group {
            .van-radio {
              .van-radio__icon {
                display: none;
              }
              .van-radio__icon--checked + .van-radio__label {
                background: #000;
                color: #fff;
              }
              .van-radio__label {
                width: 95px;
                height: 36px;
                background: #f2f2f4;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #050404;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0;
              }
            }
          }
          .van-checkbox-group {
            .van-checkbox {
              .van-checkbox__icon {
                display: none;
              }
              .van-checkbox__icon--checked + .van-checkbox__label {
                background: #000;
                color: #fff;
              }
              .van-checkbox__label {
                width: 95px;
                height: 36px;
                background: #f2f2f4;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #050404;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
  /deep/.btns {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    padding: 10px 0;
    right: 15px;
    width: 100%;
    background: #fff;
    .van-button {
      width: 90px;
      height: 32px;
      border: 1px solid #000000;
      margin-left: 10px;
      font-family: DFPKingGothicGB-Regular;
      font-size: 16px;
    }
  }
  /deep/.time-input-wrapper {
    border: 1px solid #999;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-right: 30px;
    position: relative;
    .van-cell {
      .van-cell__value {
        flex: 2;
      }
    }
    .clear-btn {
      transition: 0.2s;
      position: absolute;
      right: 5px;
      // border: 1px solid #999;
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      font-size: 14px;
      color: #fff;
      text-align: center;
      line-height: 15px;
      background: #aaa;
    }
  }
}
</style>
