<template>
  <div class="top-search">
    <div
      v-if="hasInput"
      :style="searchPadding"
      class="search"
      :class="'theme-' + theme"
    >
      <van-search
        v-model="curTopSearchMap.keyWord"
        placeholder="请输入搜索关键词"
        show-action
        :clearable="true"
        :maxlength="maxlength"
        @search="search"
        @focus="showAssociativeData = true"
        @input="debounceFn"
      >
        <template #action>
          <div @click="search">搜索</div>
        </template>
      </van-search>
      <div
        v-show="
          showAssociativeData &&
          curTopSearchMap.keyWord !== '' &&
          curTopSearchMap.keyWord != null &&
          (associativeData.length || hotQuestion.length)
        "
        class="dropdown-wrapper"
      >
        <div
          v-for="(item, index) in associativeData"
          :key="index"
        >
          <div
            v-if="index < 5"
            class="item-wrapper item"
          >
            <img :src="searchIcon" alt="" class="item-search-icon" @click="associativeClick(item, 2)" />
            <div class="text" @click="associativeClick(item, 2)" v-html="renderText(item.word)" />
            <img :src="searchArrow" alt="" class="item-search-arrow" @click="curTopSearchMap.keyWord = item.word; showAssociativeData = true; keyWordChange()" />
          </div>
          <div
            v-if="(index === 5 || index > 5 && index < 10) && (showMore || !platform)"
            class="item-wrapper item"
          >
            <img :src="searchIcon" alt="" class="item-search-icon" />
            <div class="text" @click="associativeClick(item, 2)" v-html="renderText(item.word)" />
            <img :src="searchArrow" alt="" class="item-search-arrow" @click="curTopSearchMap.keyWord = item.word; showAssociativeData = true; keyWordChange()" />
          </div>
        </div>
        <div v-if="associativeData.length > 5 && platform" class="item-more" @click="clickMore" @mouseover="hoverIcon = true" @mouseout="hoverIcon = false">
          <div>{{ showMore ? '收起更多' : '查看更多' }}</div>
          <img :src="hoverIcon ? btnMoreChecked : btnMoreNormal" :class="showMore ? 'icon-close' : 'icon'" alt="">
        </div>
        <div v-if="hotQuestion.length" class="item-hot">
          <div>搜索“<span class="hot">{{ curTopSearchMap.keyWord }}</span>”热问</div>
        </div>
        <div v-if="hotQuestion.length">
          <div
            v-for="(item, index) in hotQuestion"
            :key="index"
          >
            <div
              v-if="index < 5"
              class="item-wrapper item"
              @click="associativeClick(item, 3)"
            >
              <img :src="searchIcon" alt="" class="item-search-icon" />
              <div class="text" v-html="renderHotText(item.word)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :style="searchPadding" class="tab_nav">
      <div
        :style="filterImg || filterImg === 'atrDetail' ? 'width:90%' : 'width:100%'"
        class="tab"
        :class="'theme-' + theme"
      >
        <van-tabs
          v-model="curTopSearchMap.type"
          :color="theme === 'night' ? '#fff' : '#000'"
          background="transparent"
          :title-active-color="theme === 'night' ? '#fff' : '#000'"
          title-inactive-color="#999"
          swipeable
          @change="typeChange"
        >
          <van-tab
            v-for="(item, index) in tabsData"
            :key="index"
            :title="item.name"
            :name="item.value"
          >
            <div v-if="$route.name !== 'vSearchHome' || proj === 'audi'" @click="closeSearch">
              <div v-if="item.name === '推荐'">
                <van-tabs v-model="active" class="myvantab">
                  <van-tab title="周排行">
                    <div :class="{'myvantabcondiv':true, 'myvantabblack': background === 'black'}">
                      <h1>图片类TOP10</h1>
                      <div>
                        <CardList
                           ref="cardList"
                           :list="topRecommendSearchMapWeek.image.list"
                           :page-data-total-count="pageDataTotalCount"
                           :top-search-map="topSearchMap"
                           :top-recommend-search-map="topRecommendSearchMapWeek.image"
                           :search-params="searchParams"
                           :empty-error-type="emptyErrorType"
                           :loading="loading"
                           :has-fixed-btn="!isInIframe"
                           :double-col-shows="false"
                           :card-type="'推荐'"
                           @loadMorePage="loadMorePage"
                           @typeChange="typeChange"
                           @updateKeyword="updateKeyword"
                        />
                      </div>
                      <h1>多媒体类TOP5</h1>
                      <div>
                        <CardList
                          ref="cardList2"
                          :list="topRecommendSearchMapWeek.video.list"
                          :page-data-total-count="pageDataTotalCount"
                          :top-search-map="topSearchMap"
                          :top-recommend-search-map="topRecommendSearchMapWeek.video"
                          :search-params="searchParams"
                          :empty-error-type="emptyErrorType"
                          :loading="loading"
                          :has-fixed-btn="!isInIframe"
                          :double-col-shows="false"
                          :card-type="'推荐'"
                          @loadMorePage="loadMorePage"
                          @typeChange="typeChange"
                          @updateKeyword="updateKeyword"
                        />
                      </div>
                      <h1>文章类TOP5</h1>
                      <div>
                        <CardList
                          ref="cardList22"
                          :list="topRecommendSearchMapWeek.article.list"
                          :page-data-total-count="pageDataTotalCount"
                          :top-search-map="topSearchMap"
                          :top-recommend-search-map="topRecommendSearchMapWeek.article"
                          :search-params="searchParams"
                          :empty-error-type="emptyErrorType"
                          :loading="loading"
                          :has-fixed-btn="!isInIframe"
                          :double-col-shows="false"
                          :card-type="'推荐'"
                          @loadMorePage="loadMorePage"
                          @typeChange="typeChange"
                          @updateKeyword="updateKeyword"
                        />
                      </div>
                      <h1>话术类TOP10</h1>
                      <div>
                        <CardList
                          ref="cardList222"
                          :list="topRecommendSearchMapWeek.char.list"
                          :page-data-total-count="pageDataTotalCount"
                          :top-search-map="topSearchMap"
                          :top-recommend-search-map="topRecommendSearchMapWeek.char"
                          :search-params="searchParams"
                          :empty-error-type="emptyErrorType"
                          :loading="loading"
                          :has-fixed-btn="!isInIframe"
                          :double-col-shows="false"
                          :card-type="'推荐'"
                          @loadMorePage="loadMorePage"
                          @typeChange="typeChange"
                          @updateKeyword="updateKeyword"
                        />
                      </div>
                    </div>
                  </van-tab>
                  <van-tab title="累计排行">
                    <div :class="{'myvantabcondiv':true, 'myvantabblack': background === 'black'}">
                      <h1>图片类TOP10</h1>
                      <div>
                        <CardList
                          ref="cardList3"
                          :list="topRecommendSearchMapTotal.image.list"
                          :page-data-total-count="pageDataTotalCount"
                          :top-search-map="topSearchMap"
                          :top-recommend-search-map="topRecommendSearchMapTotal.image"
                          :search-params="searchParams"
                          :empty-error-type="emptyErrorType"
                          :loading="loading"
                          :has-fixed-btn="!isInIframe"
                          :double-col-shows="false"
                          :card-type="'推荐'"
                          @loadMorePage="loadMorePage"
                          @typeChange="typeChange"
                          @updateKeyword="updateKeyword"
                        />
                      </div>
                      <h1>多媒体类TOP5</h1>
                      <div>
                        <CardList
                           ref="cardList4"
                           :list="topRecommendSearchMapTotal.video.list"
                           :page-data-total-count="pageDataTotalCount"
                           :top-search-map="topSearchMap"
                           :top-recommend-search-map="topRecommendSearchMapTotal.video"
                           :search-params="searchParams"
                           :empty-error-type="emptyErrorType"
                           :loading="loading"
                           :has-fixed-btn="!isInIframe"
                           :double-col-shows="false"
                           :card-type="'推荐'"
                           @loadMorePage="loadMorePage"
                           @typeChange="typeChange"
                           @updateKeyword="updateKeyword"
                        />
                      </div>
                      <h1>文章类TOP5</h1>
                      <div>
                        <CardList
                          ref="cardList44"
                          :list="topRecommendSearchMapTotal.article.list"
                          :page-data-total-count="pageDataTotalCount"
                          :top-search-map="topSearchMap"
                          :top-recommend-search-map="topRecommendSearchMapTotal.article"
                          :search-params="searchParams"
                          :empty-error-type="emptyErrorType"
                          :loading="loading"
                          :has-fixed-btn="!isInIframe"
                          :double-col-shows="false"
                          :card-type="'推荐'"
                          @loadMorePage="loadMorePage"
                          @typeChange="typeChange"
                          @updateKeyword="updateKeyword"
                        />
                      </div>
                      <h1>话术类TOP10</h1>
                      <div>
                        <CardList
                          ref="cardList44"
                          :list="topRecommendSearchMapTotal.char.list"
                          :page-data-total-count="pageDataTotalCount"
                          :top-search-map="topSearchMap"
                          :top-recommend-search-map="topRecommendSearchMapTotal.char"
                          :search-params="searchParams"
                          :empty-error-type="emptyErrorType"
                          :loading="loading"
                          :has-fixed-btn="!isInIframe"
                          :double-col-shows="false"
                          :card-type="'推荐'"
                          @loadMorePage="loadMorePage"
                          @typeChange="typeChange"
                          @updateKeyword="updateKeyword"
                        />
                      </div>
                    </div>
                  </van-tab>
                </van-tabs>
              </div>
              <CardList
                v-else
                ref="cardList"
                :list="list"
                :page-data-total-count="pageDataTotalCount"
                :top-search-map="topSearchMap"
                :search-params="searchParams"
                :empty-error-type="emptyErrorType"
                :loading="loading"
                :has-fixed-btn="!isInIframe"
                @loadMorePage="loadMorePage"
                @typeChange="typeChange"
                @updateKeyword="updateKeyword"
              />
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <div
        v-show="filterImg && filterImg !== 'atrDetail'"
        class="select_icon"
      >
        <img :src="filterImg" alt="" @click="filterSelect" />
        <div v-if="filterAllShow" class="filter-box">
          <div class="filter-box-item" @click="sure('')">按相关度</div>
          <div class="filter-box-item" @click="sure('materialUpdateTime')">按最新时间</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { searchSuggest } from '@/api/modules/vSearch'
import { debounce } from '@/utils/utils'
import hotIcon from '@/assets/img/vSearch/global_icon_hot@2x.png'
import btnMoreNormal from '@/assets/img/vSearch/btn_more_normal@2x.png'
import btnMoreChecked from '@/assets/img/vSearch/btn_more_checked@2x.png'
import clearIcon from '@/assets/img/vSearch/clear-icon@2x.png'
import searchIcon from '@/assets/img/vSearch/search_icon@2x.png'
import searchArrow from '@/assets/img/vSearch/search_arrow@2x.png'
import CardList from '@/components/CardList.vue'

export default {
  name: 'TopSearch',
  components: { CardList },
  props: {
    background: {
      type: String,
      requried: false,
      default: () => {
        return ''
      }
    },
    theme: {
      type: String,
      default: 'night'
    },
    stickyClass: {
      type: String,
      default: 'sticky'
    },
    searchPadding: {
      type: Object,
      default: () => {}
    },
    filterImg: {
      type: String,
      default: null
    },
    hasInput: {
      type: Boolean,
      default: true
    },
    showAssociativeData: {
      type: Boolean,
      default: false
    },
    tabsData: {
      type: Array,
      default: () => []
    },
    topSearchMap: {
      type: Object,
      default: () => {}
    },
    topRecommendSearchMapWeek: {
      type: Object,
      required: false,
      default: () => {
        return {
          'image': {
            keyWord: '',
            type: '3',
            list: []
          },
          'video': {
            keyWord: '',
            type: '5',
            list: []
          },
          'article': {
            keyWord: '',
            type: '2',
            list: []
          },
          'char': {
            keyWord: '',
            type: '1',
            list: []
          }
        }
      }
    },
    topRecommendSearchMapTotal: {
      type: Object,
      required: false,
      default: () => {
        return {
          'image': {
            keyWord: '',
            type: '3',
            list: []
          },
          'video': {
            keyWord: '',
            type: '5',
            list: []
          },
          'article': {
            keyWord: '',
            type: '2',
            list: []
          },
          'char': {
            keyWord: '',
            type: '1',
            list: []
          }
        }
      }
    },
    maxlength: {
      type: String,
      default: '1000'
    },

    list: {
      type: Array,
      default: () => []
    },
    videoList: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    pageDataTotalCount: {
      type: Number,
      default: 0
    },
    searchParams: {
      type: Object,
      default: () => {}
    },
    emptyErrorType: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    isInIframe: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      proj: process.env.VUE_APP_PROJ,
      // 推荐里面的默认选项
      active: 0,
      filterAllShow: false,
      searchFocus: false,
      showMore: false,
      curTopSearchMap: {
        keyWord: '',
        type: '',
        querySource: ''
      },
      hoverIcon: false,
      hotIcon,
      btnMoreNormal,
      btnMoreChecked,
      clearIcon,
      searchIcon,
      searchArrow,
      platform: true,
      associativeData: [],
      hotQuestion: [],
      debounceFn: () => {}
    }
  },
  watch: {
    topSearchMap: {
      handler(val) {
        this.curTopSearchMap = { ...this.topSearchMap }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.platform = this._userAgentType().platform === 'pc'
    this.curTopSearchMap = { ...this.topSearchMap }
    // 联想搜索防抖
    this.debounceFn = debounce(this.keyWordChange, 50)
  },
  methods: {
    closeSearch() {
      this.$emit('closeSearch')
    },
    loadMorePage() {
      this.$emit('loadMorePage')
    },
    updateKeyword(keyWord) {
      this.$emit('updateKeyword', keyWord)
    },
    renderText(word) {
      const result = word ? word.replace(RegExp(this.curTopSearchMap.keyWord, 'g'), `<span style="color: #888888">${this.curTopSearchMap.keyWord}</span>`) : ''
      return result
    },
    renderHotText(word) {
      const result = word ? word.replace(RegExp(this.curTopSearchMap.keyWord, 'g'), `<span style="color: #F50537">${this.curTopSearchMap.keyWord}</span>`) : ''
      return result
    },
    clickMore() {
      this.showMore = !this.showMore
    },
    keyWordChange() {
      if (
        this.curTopSearchMap.keyWord !== '' &&
        this.curTopSearchMap.keyWord != null
      ) {
        this.searchSuggest()
      }
    },
    async searchSuggest() {
      const res = await searchSuggest({ prefix: this.curTopSearchMap.keyWord })
      if (res.code === 0) {
        this.associativeData = res.data.filter(item => item.type === 0)
        this.hotQuestion = res.data.filter(item => item.type === 2)
      }
    },
    search(val, source) {
      const querySource = source || 1
      this.curTopSearchMap.querySource = querySource
      // 当客户点击推荐栏目的搜索的时候，自动跳转到全部栏目，奥迪专用
      if (this.curTopSearchMap.type === '-1') {
        this.curTopSearchMap.type = '0'
      }
      this.$emit('search', { ...this.curTopSearchMap })
    },
    typeChange({ type }) {
      this.filterAllShow = false
      if (type) {
        this.$emit('typeChange', { ...this.curTopSearchMap, type })
      }
      else {
        this.$emit('typeChange', { ...this.curTopSearchMap })
      }
    },
    filterSelect() {
      if (this.curTopSearchMap.type === '0') {
        this.filterAllShow = !this.filterAllShow
      }
      else {
        this.$emit('filterSelect')
      }
    },
    associativeClick(item, source) {
      this.curTopSearchMap.keyWord = item.word
      if (item.type === 2) {
        this.curTopSearchMap.type = '1'
      }
      this.search(item.word, source)
    },
    sure(order) {
      this.filterAllShow = false
      this.$emit('sure', order)
    }
  }
}
</script>

<style scoped lang="less">
.top-search {
  /deep/.search {
    margin-bottom: 7px;
    position: relative;
    .van-search {
      background: transparent;
      padding: 0;
      border-bottom: 2px solid #fff;
      .van-search__content {
        background: transparent;
        border-radius: 0;
        padding-left: 0;
        .van-cell,
        .van-field__control {
          color: #999;
          font-size: 16px;
          line-height: 40px;
          padding: 0;
        }
        .van-cell {
          .van-field__left-icon {
            .van-icon {
              font-size: 22px;
            }
          }
        }
      }
      .van-search__action {
        color: #fff;
      }
    }
  }
  .tab_nav {
    position: relative !important;
    padding: 0 !important;
    .select_icon {
      position: absolute;
      top: 10px;
      right: 10px;
      img {
        width: 24px;
        height: 24px;
        line-height: 34px;
      }
      .filter-box {
        position: absolute;
        z-index: 100;
        right: 0;
        top: 50px;
        width: 110px;
        background: #FFFFFF;
        box-shadow: 0 1px 4px 0 rgba(0,0,0,0.15);
        text-align: center;
        .filter-box-item {
          color: #999;
          font-size: 16px;
          line-height: 40px;
          padding: 4px 0;
        }
        .filter-box-item:hover {
          background: rgba(57,117,198,0.10);
        }
      }
    }
  }
  /deep/.tab {
    width: 100% !important;
    .van-tabs {
      .van-tabs__wrap {
        padding: 0 40px 0 0;
        .van-tabs__nav {
          .van-tab {
            line-height: 35px;
            font-size: 16px;
          }
          .van-tabs__line {
            height: 2px;
            width: calc(100% / 5);
          }
        }
      }
    }
  }
  /deep/.van-tab__pane, .van-tab__pane-wrapper {
    width: 100%;
  }
  /deep/.search.theme-night {
    .van-search {
      border-bottom: 2px solid #fff;
      .van-search__content {
        .van-cell,
        .van-field__control {
          color: #999;
        }
      }
      .van-search__action {
        color: #fff;
      }
      .van-search__action:active {
        background: #000;
      }
    }
  }
  /deep/.search.theme-day {
    .van-search {
      border-bottom: 2px solid #000;
      .van-search__content {
        .van-cell,
        .van-field__control {
          color: #000;
        }
      }
      .van-search__action {
        color: #000;
      }
    }
  }
  .dropdown-wrapper {
    position: absolute;
    width: 339px;
    background: #fff;
    // transition-duration: 0.2s;
    overflow: hidden;
    z-index: 100;
    padding: 20px 0;
    box-sizing: border-box;
    box-shadow: 0 0 4px 5px rgba(141, 141, 141, 0.05);
    max-height: 300px;
    overflow-y: scroll;
    .item-more {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      color: #888888;
      margin-bottom: 10px;
      .icon {
        width: 12px;
        height: 12px;
      }
      .icon-close {
        width: 12px;
        height: 12px;
        transform: rotate(180deg);
      }
    }
    .item-more:hover {
      color: #0078F0;
    }
    .item-hot {
      display: flex;
      align-items: center;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      box-sizing: border-box;
      padding: 0 10px;
      margin-top: 24px;
      margin-bottom: 12px;
      .item-hot-icon {
        width: 20px;
        height: 20px;
      }
      .hot {
        color: #F50537;
      }
    }
    .item-wrapper {
      box-sizing: border-box;
      padding: 8px 10px;
      position: relative;
    }
    .item {
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      line-height: 24px;
      cursor: pointer;
      transition-duration: 0.2s;
      display: flex;
      align-items: center;
      .text {
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .item-search-icon {
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        margin-right: 10px;
      }
      .item-search-arrow {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 10px;
      }
    }
    .item-wrapper:hover {
      background: #f5f5f5;
    }
  }
}
</style>
<style lang="less">
  .myvantabblack{
    h1{color:#ffffff;}
  }
  .myvantab{
    margin:10px 10px;
    .van-tabs__wrap{padding:0px !important;}
    .van-tabs__line{display:none !important;}
    .van-tab{
      background-color:#808080;
      color:#ffffff;
    }
    h1{font-size:14px;}
    .van-tab--active{
      background-color:#ff9400;
    }
    .myvantabcondiv{
      padding-top:10px;
      padding-bottom:10px;
    }
  }
</style>
