<template>
  <div class="cardList-media">
    <div v-if="!doubleColShows" class="list-item list-item-media">
      <van-checkbox
        v-if="isCheckOpen"
        :name="item.cardData.id"
        shape="square"
        checked-color="#000"
      ></van-checkbox>
      <van-radio v-if="feedBackCheckOpen" :name="index"></van-radio>
      <div
        class="media"
        :class="isCheckOpen || feedBackCheckOpen ? 'media-294' : 'media-325'"
        @click="!isCheckOpen && !feedBackCheckOpen && toDetailPage('videoDetail', item, index)"
      >
        <div v-if="proj === 'audi'" class="toplevel">{{item.cardData.topStr}}</div>
        <div class="content">
          <div class="left">
            <img
              v-lazy="item.cardData.hasImageUrl ? item.cardData.imageUrl : audioBg"
              :src="item.cardData.hasImageUrl ? item.cardData.imageUrl : audioBg"
              alt=""
            />
            <span v-if="proj === 'audi'" class="media-time-wrapper">
              <van-icon name="play" />
              <span class="media-time">{{
                getDurationFormat(item.cardData.duration)
              }}</span>
            </span>
          </div>
          <div class="right">
            <div
              class="top keywords-mark-f00"
              v-html="item.cardData.title || ''"
            ></div>
            <div v-if="proj === 'audi'" class="format">{{ item.cardData.format || '/' }}</div>
            <CardListTags :tags="item.cardData.tags ? item.cardData.tags.split(',') : []" />
          </div>
        </div>
        <div class="card-bottom-wrapper">
          <div class="bottom">
            <div>
              {{ item.cardData.orgName || '/' }}&nbsp;&nbsp;&nbsp;{{
                item.cardData.time
                  ? timeTypeFomate(item.cardData.time, 'YYYY-MM-DD')
                  : '--'
              }}
            </div>
          </div>
          <span
            v-show="!isCheckOpen && !notInQw"
            @click.stop="
              share('videoDetail', {
                ...item,
                offset:
                  (searchParams.pageIndex - 1) * searchParams.pageSize +
                  index +
                  1
              })
            "
          >
            <img
              style="width: 18px;height: 15px;"
              :src="proj === 'audi' ? audiSendIcon : sendIcon"
              alt=""
            />转发
          </span>
        </div>
      </div>
    </div>
    <div v-if="doubleColShows" class="list-item list-item-picture">
      <van-checkbox
        v-if="isCheckOpen"
        :name="item.cardData.id"
        shape="square"
        checked-color="#000"
      ></van-checkbox>
      <van-radio v-if="feedBackCheckOpen" :name="index"></van-radio>
      <div
        class="picture"
        @click="
          !isCheckOpen && !feedBackCheckOpen && toDetailPage('videoDetail', item, index)
        "
      >
        <div class="image">
          <img
            v-lazy="item.cardData.hasImageUrl ? item.cardData.imageUrl : audioBg"
            :src="item.cardData.hasImageUrl ? item.cardData.imageUrl : audioBg"
            alt=""
          />
          <span v-if="proj === 'audi'" class="media-time-wrapper">
            <van-icon name="play" />
            <span class="media-time">{{
              getDurationFormat(item.cardData.duration)
            }}</span>
          </span>
        </div>
        <div class="card-bottom-wrapper">
          <div
            class="name keywords-mark-f00"
            v-html="item.cardData.title"
          ></div>
          <img
            v-show="!isCheckOpen && !notInQw"
            style="width: 18px;height: 15px;"
            :src="proj === 'audi' ? audiSendIcon : sendIcon"
            alt=""
            @click.stop="
              share('videoDetail', {
                ...item,
                offset:
                  (searchParams.pageIndex - 1) * searchParams.pageSize +
                  index +
                  1
              })
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import audioBg from '@/assets/img/vSearch/audioBg.png'
import { getUrlParamsObj } from '@/scripts/utils'
import sendIcon from '@/assets/img/vSearch/icon_share.png'
import audiSendIcon from '@/assets/img/vSearch/send_icon.png'
import CardListTags from '@/components/CardList-tags.vue'

export default {
  name: 'Media',
  components: { CardListTags },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: () => {}
    },
    doubleColShows: {
      type: Boolean,
      default: false
    },
    isCheckOpen: {
      type: Boolean,
      default: false
    },
    feedBackCheckOpen: {
      type: Boolean,
      default: false
    },
    searchParams: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      sendIcon,
      audiSendIcon,
      notInQw: false,
      proj: process.env.VUE_APP_PROJ,
      audioBg
    }
  },
  created() {
    this.notInQw = getUrlParamsObj().notInQw || ''
  },
  mounted() {
    if (document.querySelectorAll('#tags')) {
      document.querySelectorAll('#tags').forEach(item => {
        item.addEventListener(
          'touchmove',
          function(event) {
            event.stopPropagation()
          },
          true
        )
      })
    }
  },
  methods: {
    getDurationFormat(duration) {
      if (!duration) return '--:--'
      const second = duration % 60
      const minute = parseInt(duration / 60) % 60
      const hour = parseInt(duration / 60 / 60)
      return `${hour > 9 ? hour : '0' + String(hour)}:${
        minute > 9 ? minute : '0' + String(minute)
      }:${second > 9 ? second : '0' + String(second)}`
    },
    timeTypeFomate(date, type = 'YYYY-MM-DD HH:mm:ss') {
      if (new Date(date).getDate() === new Date().getDate()) {
        return '今日发布'
      }
      else if (
        new Date().getTime() - new Date(date).getTime() <=
        7 * 24 * 60 * 60 * 1000
      ) {
        const dayNum = Math.ceil(
          (new Date().getTime() - new Date(date).getTime()) /
            (24 * 60 * 60 * 1000)
        )
        console.log(dayNum)
        return `${dayNum}天前`
      }
      else {
        return moment(new Date(Number(date))).format(type)
      }
    },

    toDetailPage(pathName, item, index) {
      this.$emit('toDetailPage', pathName, item, index)
    },
    share(pathName, item) {
      this.$emit('share', pathName, item)
    }
  }
}
</script>
<style lang="less" scope>
.list-item-media {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .media-294 {
    width: 90%;
    transition-duration: 0.1s;
  }
  .media-325 {
    width: 100%;
    transition-duration: 0.1s;
  }
  .van-checkbox {
    width: 16px;
    .van-checkbox__icon {
      .van-icon {
        border-color: rgba(0, 0, 0, 0.5);
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
      .van-icon::before {
        font-size: 12px;
        position: relative;
        top: -2px;
      }
    }
    .van-checkbox__label {
      display: none;
    }
  }
  .van-radio {
    width: 16px;
    .van-radio__icon {
      .van-icon {
        border-color: rgba(0, 0, 0, 0.5);
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
      .van-icon::before {
        font-size: 12px;
        position: relative;
        top: -2px;
      }
    }
    .van-radio__icon--checked .van-icon {
      background-color: #000;
    }
    .van-radio__label {
      display: none;
    }
  }
}
.media {
  position:relative;
  .toplevel{
    position:absolute;
    right:0px;top:0px;
    color:#ff9400;
    z-index:33;
    font-size:12px;font-weight:bolder;
  }
  .content {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .right {
      width: 170px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // height: 90px;
      .top {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #050404;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 10px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .format {
        font-family: AudiTypeVar-Normal;
        font-size: 12px;
        color: #666666;
      }
      .bottom {
        font-family: PingFangSC-Regular;
        font-size: 10px;
        color: #999999;
        line-height: 16px;
        display: flex;
        justify-content: space-between;
      }
    }
    .left {
      min-width: 120px;
      max-width: 120px;
      height: 90px;
      background-size: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-right: 10px;
      position: relative;
      img {
        width: 100%;
      }
      .media-time-wrapper {
        position: absolute;
        bottom: 5px;
        right: 5px;
        width: 52px;
        height: 16px;
        background: rgba(204, 204, 204, 0.9);
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;
        .media-time {
          font-family: AudiTypeVar-Normal;
          font-size: 12px;
          color: #333333;
          transform: scale(0.8);
        }
        .van-icon {
          font-size: 10px;
        }
      }
    }
  }
  .card-bottom-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    .bottom {
      font-family: DFPKingGothicGB-Regular;
      font-size: 10px;
      color: #999999;
      line-height: 16px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    span {
      opacity: 0.9;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 13px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      display: flex;
      align-items: center;
      margin-right: 8px;
      img {
        margin-right: 4px;
      }
    }
  }
}
</style>
