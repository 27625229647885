<template>
  <div v-if="!topSearchMap.keyWord">
    <div v-if="list[0] && list[0].videoList && list[0].videoList.length" class="list-all">
      <div class="all-more">
        <span @click="proj === 'audi' ? typeChange('5') : typeChange('7')">更多</span>
        <img class="all-more-icon" src="@/assets/img/vSearch/icon_arrow_right.png" alt="" />
      </div>
      <div class="all-title">
        <span class="keywords-mark-f00">{{ topSearchMap.keyWord }}</span>
        <span>{{ proj === 'audi' ?  '多媒体' : '视频' }}</span>
      </div>
      <div id="video" class="all-video">
        <div v-for="(item, index) in list[0].videoList" :key="index" class="all-video-item">
          <van-radio v-if="feedBackCheckOpen" :name="{ item, index }" />
          <img
            v-lazy="item.imageUrl || audioBg"
            class="all-video-item-img"
            :src="item.imageUrl || audioBg"
            alt=""
            @click="!feedBackCheckOpen && allToDetailPage('videoDetail', item, index)"
          />
          <img
            class="all-video-item-play"
            src="@/assets/img/vSearch/icon_play.png"
            alt=""
            @click="!feedBackCheckOpen && allToDetailPage('videoDetail', item, index)"
          />
          <div
            class="all-video-item-title"
            @click="!feedBackCheckOpen && allToDetailPage('videoDetail', item, index)"
            v-html="filterLabels(item.title || '')"
          />
          <img
            class="all-share-icon"
            :src="proj === 'audi' ? audiSendIcon : sendIcon"
            alt=""
            @click.stop="allShare('videoDetail', item, index)"
          />
        </div>
      </div>
    </div>
    <div v-if="list[0] && list[0].speechList && list[0].speechList.length" class="list-all">
      <div v-for="(item, index) in list[0].speechList" :key="index" class="all-speech-item">
        <van-radio v-if="feedBackCheckOpen" :name="{ item, index }" />
        <div :style="feedBackCheckOpen ? 'width: 90%' : 'width: 100%'">
          <div class="all-text">话术</div>
          <div class="all-title" v-html="filterLabels(item.title || '')" />
          <div class="all-speech-content" v-html="item.content" />
          <CardListTags :tags="item.tags ? item.tags.split(',') : []" />
          <div class="all-speech-btns">
            <div v-if="+item.link === 1" class="btn" @click="!feedBackCheckOpen && allToDetailPage('speechArtDetail', item, index)">
              <img class="btn-icon" src="@/assets/img/vSearch/matter@2x.png" alt=""/>
              <div>关联素材</div>
            </div>
            <div
              class="btn"
              @click="allOpenPopupForm(item, index)"
            >
              <img class="btn-icon" :src="proj === 'audi' ? audiSendIcon : sendIcon" alt="" />
              <div>发送</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="list[0] && list[0].graphicList && list[0].graphicList.length" class="list-all">
      <div v-for="(item, index) in list[0].graphicList" :key="index" class="all-graphic-item">
        <van-radio v-if="feedBackCheckOpen" :name="{ item, index }" />
        <div :style="feedBackCheckOpen ? 'width: 90%' : 'width: 100%'" @click="!feedBackCheckOpen && allToDetailPage('articleDetail', item, index)">
          <div>
            <div class="all-text">{{ proj === 'audi' ? '文章' : '图文' }}</div>
            <div class="all-title" v-html="filterLabels(item.title || '')" />
            <div class="all-graphic-content" v-html="item.description || ''" />
            <img v-if="item.imageUrl" class="all-graphic-img" :src="item.imageUrl" />
          </div>
          <div class="all-graphic-desc">
            <div>
              {{ item.orgName || '/' }}&nbsp;&nbsp;&nbsp;{{
                item.updateTime
                  ? timeTypeFomate(item.updateTime)
                  : '--'
              }}
            </div>
            <div class="all-share">
              <img
                class="icon"
                :src="proj === 'audi' ? audiSendIcon : sendIcon"
                alt=""
                @click.stop="allShare('articleDetail', item, index)"
              />
              <span>转发</span>
            </div>
          </div>
          <CardListTags :tags="item.tags ? item.tags.split(',') : []" />
        </div>
      </div>
    </div>
    <div v-if="list[0] && list[0].imageList && list[0].imageList.length" class="list-all">
      <div class="all-more">
        <span @click="typeChange('3')">更多</span>
        <img class="all-more-icon" src="@/assets/img/vSearch/icon_arrow_right.png" alt="" />
      </div>
      <div class="all-title">
        <span class="keywords-mark-f00">{{ topSearchMap.keyWord }}</span>
        <span>图片</span>
      </div>
      <div class="all-img">
        <div v-for="(item, index) in list[0].imageList" :key="index" class="all-img-item">
          <van-radio v-if="feedBackCheckOpen" :name="{ item, index }" />
          <img
            v-lazy="item.imageUrl"
            class="all-img-item-img"
            :src="item.imageUrl"
            alt=""
            @click="!feedBackCheckOpen && allToDetailPage('graphicDetail', item, index)"
          />
          <div class="all-img-item-title" @click="!feedBackCheckOpen && allToDetailPage('graphicDetail', item, index)" v-html="filterLabels(item.title || '')" />
          <img
            class="all-share-icon"
            :src="proj === 'audi' ? audiSendIcon : sendIcon"
            alt=""
            @click.stop="allShare('graphicDetail', item, index)"
          />
        </div>
      </div>
    </div>
    <div v-if="list[0] && list[0].posterList && list[0].posterList.length && !isInIframe" class="list-all">
      <div class="all-more">
        <span @click="proj === 'audi' ? typeChange('4') : typeChange('9')">更多</span>
        <img class="all-more-icon" src="@/assets/img/vSearch/icon_arrow_right.png" alt="" />
      </div>
      <div class="all-title">
        <span class="keywords-mark-f00">{{ topSearchMap.keyWord }}</span>
        <span>海报</span>
      </div>
      <div class="all-img">
        <div v-for="(item, index) in list[0].posterList" :key="index" class="all-img-item">
          <van-radio v-if="feedBackCheckOpen" :name="{ item, index }" />
          <img
            v-lazy="item.imageUrl"
            class="all-img-item-img"
            :src="item.imageUrl"
            alt=""
            @click="!feedBackCheckOpen && allToDetailPage('posterDetail', item, index)"
          />
          <div class="all-img-item-title" @click="!feedBackCheckOpen && allToDetailPage('posterDetail', item, index)" v-html="filterLabels(item.title || '')" />
        </div>
      </div>
    </div>
    <div v-if="list[0] && list[0].articleList && list[0].articleList.length" class="list-all">
      <div class="all-article">
        <div class="all-article-content">
          <van-radio v-if="feedBackCheckOpen" :name="{ item: list[0].articleList[0], index: 0 }" />
          <img
            class="all-article-content-icon"
            src="@/assets/img/vSearch/file_icon@2x.png"
            alt=""
          />
          <div
            class="all-article-content-title"
            v-html="filterLabels(list[0].articleList[0].title || '')"
          />
        </div>
        <img
          class="all-article-share"
          src="@/assets/img/vSearch/share_icon@2x.png"
          alt=""
          @click.stop="allShare('fileDetail', list[0].articleList[0], index)"
        />
      </div>
      <CardListTags :tags="list[0].articleList[0].tags ? list[0].articleList[0].tags.split(',') : []" />
    </div>
    <div v-if="list[0] && list[0].activityList.length" class="list-all" style="padding: 0">
      <Activity
        :item="list[0] && list[0].activityList[0]"
        :index="0"
        :feed-back-check-open="feedBackCheckOpen"
      />
    </div>
  </div>
  <div v-else>
    <div v-if="isImage(list[0].atlas.answer)" class="list-all">
      <div class="all-title">{{ list[0].atlas.neoNodeEntity.name }}</div>
      <img class="img" :src="list[0].atlas.answer" alt="">
      <div v-if="proj !== 'audi'" class="all-origin">来自知识图谱</div>
    </div>
    <div v-else-if="list[0].atlas.neoNodeEntity && list[0].atlas.neoNodeEntity.nodeType === '车型'" class="list-all">
      <div class="all-title">{{ list[0].atlas.neoNodeEntity.name }}</div>
      <div class="all-type">{{ list[0].atlas.neoNodeEntity.nodeType }}</div>
      <div class="all-time">发布时间：{{ list[0].atlas.neoNodeEntity.propertiesMap.createTime ? timeTypeFomate(list[0].atlas.neoNodeEntity.propertiesMap.createTime) : '--' }}</div>
      <img class="model-img" :src="list[0].atlas.neoNodeEntity.propertiesMap['车型图片']" alt="">
      <div class="model-tabs">
        <div :class="modelTab === 'config' ? 'model-tabs-item model-tabs-item__active' : 'model-tabs-item'" @click="modelTab = 'config'">配置</div>
        <div v-if="list[0].atlas.relationEntityList.filter(item => item.targetNodeType === '车联网服务').length" :class="modelTab === 'service' ? 'model-tabs-item model-tabs-item__active' : 'model-tabs-item'" @click="modelTab = 'service'">服务</div>
      </div>
      <div v-if="modelTab === 'config'" class="model-tabs-content">
        <div v-for="(val, key, i) in list[0].atlas.neoNodeEntity.propertiesMap" v-show="key !== '车型图片'" :key="i" class="model-tabs-content-list">
          <div class="key">{{ key }}:</div>
          <div v-if="key === 'createTime'" class="val">{{ val ? timeTypeFomate(val) : '--' }}</div>
          <div v-else class="val">{{ val || '/' }}</div>
        </div>
      </div>
      <div v-if="modelTab === 'service' && list[0].atlas.relationEntityList.filter(item => item.targetNodeType === '车联网服务').length">
        <div v-for="(item, i) in list[0].atlas.relationEntityList.filter(item => item.targetNodeType === '车联网服务')" :key="i" class="model-tabs-content">
          <div v-for="(val, key, index) in item.targetNode.propertiesMap" :key="index" class="model-tabs-content-list">
            <div class="key">{{ key }}:</div>
            <div v-if="key === 'createTime'" class="val">{{ val ? timeTypeFomate(val) : '--' }}</div>
            <div v-else class="val">{{ val || '/' }}</div>
          </div>
        </div>
      </div>
      <div v-if="proj !== 'audi'" class="all-origin">来自知识图谱</div>
    </div>
    <div v-else-if="list[0].atlas.neoNodeEntity && list[0].atlas.neoNodeEntity.nodeType === '车系'" class="list-all">
      <div class="all-title">{{ list[0].atlas.neoNodeEntity.name }}</div>
      <div class="all-type">{{ list[0].atlas.neoNodeEntity.nodeType }}</div>
      <div class="all-time">发布时间：{{ list[0].atlas.neoNodeEntity.propertiesMap.createTime ? timeTypeFomate(list[0].atlas.neoNodeEntity.propertiesMap.createTime) : '--' }}</div>
      <div v-if="modelList.length" class="model-list">
        <div
          v-for="(item, i) in modelList"
          v-show="(!showMoreModel && i === 0) || showMoreModel"
          :key="i"
          class="model-list-item"
        >
          <img :src="item.targetNode.propertiesMap['车型图片']" alt="">
          <div class="model-list-item-info">
            <div class="name" @click="updateKeyword(item.targetNode.name)">{{ item.targetNode.name }}</div>
            <div v-if="dealerList.length" class="dealer" @click="showDealer = true">
              <div>经销商</div>
              <img src="@/assets/img/vSearch/icon_arrow_right.png" alt="">
            </div>
            <div class="time">发布时间：{{ item.targetNode.propertiesMap.createTime ? timeTypeFomate(item.targetNode.propertiesMap.createTime) : '--' }}</div>
          </div>
        </div>
        <div v-if="modelList.length > 1" class="model-more" @click="showMoreModel = !showMoreModel">{{ showMoreModel ? '收起更多' : '查看更多'}}</div>
      </div>
      <div v-if="proj !== 'audi'" class="all-origin">来自知识图谱</div>
    </div>
    <div v-else-if="list[0].atlas.neoNodeEntity && list[0].atlas.neoNodeEntity.nodeType === '品牌'" class="list-all">
      <div class="all-title">{{ list[0].atlas.neoNodeEntity.name }}</div>
      <div class="all-type">{{ list[0].atlas.neoNodeEntity.nodeType }}</div>
      <div class="all-time">发布时间：{{ list[0].atlas.neoNodeEntity.propertiesMap.createTime ? timeTypeFomate(list[0].atlas.neoNodeEntity.propertiesMap.createTime) : '--' }}</div>
      <div id="seriesList" class="series-list">
        <div
          v-for="(item, i) in list[0].atlas.relationEntityList.filter(item => item.targetNode.nodeType === '车系')"
          :key="i"
          :class="curSeries === item.targetNode.name ? 'series-list-item series-list-item__active' : 'series-list-item'"
          @click="clickSeries(item)"
        >{{ item.targetNode.name }}</div>
      </div>
      <div v-if="modelList.length" class="model-list">
        <div
          v-for="(item, i) in modelList"
          v-show="(!showMoreModel && i === 0) || showMoreModel"
          :key="i"
          class="model-list-item"
        >
          <img :src="item.targetNode.propertiesMap['车型图片']" alt="">
          <div class="model-list-item-info">
            <div class="name" @click="updateKeyword(item.targetNode.name)">{{ item.targetNode.name }}</div>
            <div v-if="dealerList.length" class="dealer" @click="showDealer = true">
              <div>经销商</div>
              <img src="@/assets/img/vSearch/icon_arrow_right.png" alt="">
            </div>
            <div class="time">发布时间：{{ item.targetNode.propertiesMap.createTime ? timeTypeFomate(item.targetNode.propertiesMap.createTime) : '--' }}</div>
          </div>
        </div>
        <div v-if="modelList.length > 1" class="model-more" @click="showMoreModel = !showMoreModel">{{ showMoreModel ? '收起更多' : '查看更多'}}</div>
      </div>
      <div v-if="proj !== 'audi'" class="all-origin">来自知识图谱</div>
    </div>
    <div v-else-if="list[0] && list[0].atlas && list[0].atlas.displayDetail" class="list-all">
      <div class="all-title">{{ list[0].atlas.answer }}</div>
      <div class="all-atlas-list">
        <div v-for="(item, i) in list[0].atlas.displayDetail" :key="i">
          <div
            v-if="i < 4 && !atlasMore"
            class="atlas-item"
            :style="isImage(item.value) ? 'width: calc(100% - 40px)' : ''"
            @click="item.key === 'createTime' ? updateKeyword(timeTypeFomate(item.value)) : updateKeyword(item.value)"
          >
            <div class="key">{{ item.key }}</div>
            <div v-if="item.key === 'createTime'" class="value">{{ item.value ? timeTypeFomate(item.value) : '--' }}</div>
            <div v-else class="value">{{ item.value }}</div>
            <img v-if="isImage(item.value)" src="@/assets/img/vSearch/atlas_icon.png" alt="" @click.stop="showImage(item)">
          </div>
          <div v-if="atlasMore" class="atlas-item" :style="isImage(item.value) ? 'width: calc(100% - 40px)' : ''" @click="item.key === 'createTime' ? updateKeyword(timeTypeFomate(item.value)) : updateKeyword(item.value)">
            <div class="key">{{ item.key }}</div>
            <div v-if="item.key === 'createTime'" class="value">{{ item.value ? timeTypeFomate(item.value) : '--' }}</div>
            <div v-else class="value">{{ item.value }}</div>
            <img v-if="isImage(item.value)" src="@/assets/img/vSearch/atlas_icon.png" alt="" @click.stop="showImage(item)">
          </div>
        </div>
      </div>
      <div v-if="list[0].atlas.displayDetail.length > 4" class="all-atlas-more" @click="atlasMore = !atlasMore">{{ atlasMore ? '收起更多' : '查看更多' }}</div>
    </div>
    <div v-if="list[0] && list[0].videoList.length" class="list-all">
      <div class="all-more">
        <span @click="proj === 'audi' ? typeChange('5') : typeChange('7')">更多</span>
        <img class="all-more-icon" src="@/assets/img/vSearch/icon_arrow_right.png" alt="" />
      </div>
      <div class="all-title">
        <span class="keywords-mark-f00">{{ topSearchMap.keyWord }}</span>
        <span>{{ proj === 'audi' ?  '多媒体' : '视频' }}</span>
      </div>
      <div id="video" class="all-video">
        <div v-for="(item, index) in list[0].videoList" :key="index" class="all-video-item">
          <van-radio v-if="feedBackCheckOpen" :name="{ item, index }" />
          <img
            v-lazy="item.imageUrl || audioBg"
            class="all-video-item-img"
            :src="item.imageUrl || audioBg"
            alt=""
            @click="!feedBackCheckOpen && allToDetailPage('videoDetail', item, index)"
          />
          <img
            class="all-video-item-play"
            src="@/assets/img/vSearch/icon_play.png"
            alt=""
            @click="!feedBackCheckOpen && allToDetailPage('videoDetail', item, index)"
          />
          <div
            class="all-video-item-title"
            @click="!feedBackCheckOpen && allToDetailPage('videoDetail', item, index)"
            v-html="filterLabels(item.title || '')"
          />
          <img
            class="all-share-icon"
            :src="proj === 'audi' ? audiSendIcon : sendIcon"
            alt=""
            @click.stop="allShare('videoDetail', item, index)"
          />
        </div>
      </div>
    </div>
    <div v-if="list[0] && list[0].imageList.length" class="list-all">
      <div class="all-more">
        <span @click="typeChange('3')">更多</span>
        <img class="all-more-icon" src="@/assets/img/vSearch/icon_arrow_right.png" alt="" />
      </div>
      <div class="all-title">
        <span class="keywords-mark-f00">{{ topSearchMap.keyWord }}</span>
        <span>图片</span>
      </div>
      <div class="all-img">
        <div v-for="(item, index) in list[0].imageList" :key="index" class="all-img-item">
          <van-radio v-if="feedBackCheckOpen" :name="{ item, index }" />
          <img
            v-lazy="item.imageUrl"
            class="all-img-item-img"
            :src="item.imageUrl"
            alt=""
            @click="!feedBackCheckOpen && allToDetailPage('graphicDetail', item, index)"
          />
          <div class="all-img-item-title" @click="!feedBackCheckOpen && allToDetailPage('graphicDetail', item, index)" v-html="filterLabels(item.title || '')" />
          <img
            class="all-share-icon"
            :src="proj === 'audi' ? audiSendIcon : sendIcon"
            alt=""
            @click.stop="allShare('graphicDetail', item, index)"
          />
        </div>
      </div>
    </div>
    <div v-if="list[0] && list[0].posterList.length && !isInIframe" class="list-all">
      <div class="all-more">
        <span @click="proj === 'audi' ? typeChange('4') : typeChange('9')">更多</span>
        <img class="all-more-icon" src="@/assets/img/vSearch/icon_arrow_right.png" alt="" />
      </div>
      <div class="all-title">
        <span class="keywords-mark-f00">{{ topSearchMap.keyWord }}</span>
        <span>海报</span>
      </div>
      <div class="all-img">
        <div v-for="(item, index) in list[0].posterList" :key="index" class="all-img-item">
          <van-radio v-if="feedBackCheckOpen" :name="{ item, index }" />
          <img
            v-lazy="item.imageUrl"
            class="all-img-item-img"
            :src="item.imageUrl"
            alt=""
            @click="!feedBackCheckOpen && allToDetailPage('posterDetail', item, index)"
          />
          <div class="all-img-item-title" @click="!feedBackCheckOpen && allToDetailPage('posterDetail', item, index)" v-html="filterLabels(item.title || '')" />
        </div>
      </div>
    </div>
    <div v-if="list[0] && list[0].allList && list[0].allList.length" class="list-all" style="background: #f5f7fa; padding: 0">
      <div v-for="(item, index) in list[0].allList" :key="index">
        <div v-if="item.materialType === '1'" class="list-all all-speech-item">
          <van-radio v-if="feedBackCheckOpen" :name="{ item, index }" />
          <div :style="feedBackCheckOpen ? 'width: 90%' : 'width: 100%'">
            <div class="all-text all-text-all">话术</div>
            <div class="all-title" v-html="filterLabels(item.title || '')" />
            <div class="all-speech-content" v-html="item.content" />
            <CardListTags :tags="item.tags ? item.tags.split(',') : []" />
            <div class="all-speech-btns">
              <div v-if="+item.link === 1" class="btn" @click="!feedBackCheckOpen && allToDetailPage('speechArtDetail', item, index)">
                <img class="btn-icon" src="@/assets/img/vSearch/matter@2x.png" alt=""/>
                <div>关联素材</div>
              </div>
              <div
                class="btn"
                @click="allOpenPopupForm(item, index)"
              >
                <img class="btn-icon" :src="proj === 'audi' ? audiSendIcon : sendIcon" alt="" />
                <div>发送</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.materialType === '2'" class="list-all all-graphic-item">
          <div :style="feedBackCheckOpen ? 'width: 90%' : 'width: 100%'" @click="!feedBackCheckOpen && allToDetailPage('articleDetail', item, index)">
            <div>
              <div class="all-text all-text-all">{{ proj === 'audi' ? '文章' : '图文' }}</div>
              <div class="all-title" v-html="filterLabels(item.title || '')" />
              <div class="all-graphic-content" v-html="item.description || ''" />
              <img v-if="item.imageUrl" class="all-graphic-img all-graphic-img-all" :src="item.imageUrl" />
            </div>
            <div class="all-graphic-desc">
              <div>
                {{ item.orgName || '/' }}&nbsp;&nbsp;&nbsp;{{
                  item.updateTime
                    ? timeTypeFomate(item.updateTime)
                    : '--'
                }}
              </div>
              <div class="all-share">
                <img
                  class="icon"
                  :src="proj === 'audi' ? audiSendIcon : sendIcon"
                  alt=""
                  @click.stop="allShare('articleDetail', item, index)"
                />
                <span>转发</span>
              </div>
            </div>
            <CardListTags :tags="item.tags ? item.tags.split(',') : []" />
          </div>
        </div>
        <div v-if="(item.materialType === '8' || item.materialType === '6') && !isInIframe" class="list-all">
          <div class="all-article">
            <div class="all-article-content">
              <van-radio v-if="feedBackCheckOpen" :name="{ item, index }" />
              <img
                class="all-article-content-icon"
                src="@/assets/img/vSearch/file_icon@2x.png"
                alt=""
              />
              <div
                class="all-article-content-title"
                v-html="filterLabels(item.title || '')"
              />
            </div>
            <img
              class="all-article-share"
              src="@/assets/img/vSearch/share_icon@2x.png"
              alt=""
              @click.stop="allShare('fileDetail', item, index)"
            />
          </div>
          <CardListTags :tags="item.tags ? item.tags.split(',') : []" />
        </div>
        <div v-if="item.materialType === '10'" class="list-all" style="padding: 0">
          <Activity
            :item="item"
            :index="index"
            :feed-back-check-open="feedBackCheckOpen"
          />
        </div>
      </div>
    </div>
    <van-dialog
      v-model="imgDialogVisible"
      :show-confirm-button="false"
      :close-on-click-overlay="true"
    >
      <img :src="curImage.value" alt="" class="show-img">
    </van-dialog>
    <van-popup
      v-model="showDealer"
      position="bottom"
      :close-on-click-overlay="true"
      :closeable="true"
    >
      <div class="dealer-list">
        <div
          v-for="(item, i) in dealerList"
          :key="i"
          class="dealer-list-item"
          @click="updateKeyword(item.targetNode.name)"
        >
          {{ item.targetNode.name }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import moment from 'moment'
import sendIcon from '@/assets/img/vSearch/icon_share.png'
import audiSendIcon from '@/assets/img/vSearch/send_icon.png'
import { getAtlasSeries } from '@/api/modules/vSearch'
import CardListTags from '@/components/CardList-tags.vue'
import Activity from '@/components/CardList-activity.vue'
import audioBg from '@/assets/img/vSearch/audioBg.png'

export default {
  name: 'CardListAll',
  components: { CardListTags, Activity },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    topSearchMap: {
      type: Object,
      default: () => {}
    },
    feedBackCheckOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sendIcon,
      audiSendIcon,
      audioBg,
      proj: process.env.VUE_APP_PROJ,
      atlasMore: false,
      imgDialogVisible: false,
      curImage: '',
      modelTab: 'config',
      curSeries: '',
      modelList: [],
      showMoreModel: false,
      showDealer: false,
      dealerList: []
    }
  },
  computed: {
    isInIframe() {
      return self !== top
    }
  },
  watch: {
  },
  async created() {
    if (this.list[0].atlas.neoNodeEntity && this.list[0].atlas.neoNodeEntity.nodeType === '品牌') {
      const series = this.list[0].atlas.relationEntityList.filter(item => item.targetNode.nodeType === '车系')
      this.clickSeries(series[0])
    }
    if (this.list[0].atlas.neoNodeEntity && this.list[0].atlas.neoNodeEntity.nodeType === '车系') {
      const item = {
        targetNode: {
          name: this.list[0].atlas.neoNodeEntity.name,
          id: this.list[0].atlas.neoNodeEntity.id
        }
      }
      this.clickSeries(item)
    }
  },
  mounted() {
    if (document.querySelector('#seriesList')) {
      document.querySelector('#seriesList').addEventListener(
        'touchmove',
        function(event) {
          event.stopPropagation()
        },
        true
      )
    }
    if (document.querySelector('#video')) {
      document.querySelector('#video').addEventListener(
        'touchmove',
        function(event) {
          event.stopPropagation()
        },
        true
      )
    }
    if (document.querySelectorAll('#tags')) {
      document.querySelectorAll('#tags').forEach(item => {
        item.addEventListener(
          'touchmove',
          function(event) {
            event.stopPropagation()
          },
          true
        )
      })
    }
  },
  methods: {
    filterLabels(str = '') {
      str = str
        .split('<em>')
        .join('')
        .split('</em>')
        .join('')
      const result = this.topSearchMap.keyWord ? str.replace(RegExp(this.topSearchMap.keyWord, 'g'), `<span style="color: #f00">${this.topSearchMap.keyWord}</span>`) : str
      return result
    },
    isImage(url) {
      return /\.jpg|png|gif|jpeg$/.test(url)
    },
    showImage(item) {
      this.curImage = item
      this.imgDialogVisible = true
    },
    async clickSeries(item) {
      this.curSeries = item.targetNode.name
      const res = await getAtlasSeries({ id: item.targetNode.id })
      this.modelList = res.data ? res.data.neoRelationEntityList.filter(item => item.targetNodeType === '车型') : []
      this.dealerList = res.data ? res.data.neoRelationEntityList.filter(item => item.targetNodeType === '经销商') : []
    },
    updateKeyword(keyWord) {
      this.$emit('updateKeyword', keyWord)
    },
    allToDetailPage(pathName, item, index) {
      this.$emit('allToDetailPage', { pathName, item, index })
    },
    allOpenPopupForm(item, index) {
      this.$emit('allOpenPopupForm', { item, index })
    },
    typeChange(type) {
      this.$emit('typeChange', type)
    },
    allShare(pathName, item, index) {
      this.$emit('allShare', { pathName, item, index })
    },
    timeTypeFomate(date, type = 'YYYY-MM-DD HH:mm:ss') {
      if (new Date(date).getDate() === new Date().getDate()) {
        return '今日发布'
      }
      else if (
        new Date().getTime() - new Date(date).getTime() <=
        7 * 24 * 60 * 60 * 1000
      ) {
        const dayNum = Math.ceil(
          (new Date().getTime() - new Date(date).getTime()) /
            (24 * 60 * 60 * 1000)
        )
        console.log(dayNum)
        return `${dayNum}天前`
      }
      else {
        return moment(new Date(Number(date))).format(type)
      }
    }
  }
}
</script>

<style lang="less" scope>
.show-img {
  width: 100%;
}
.list-all {
  position: relative;
  width: 100%;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 15px;
  .img {
    width: 200px;
    height: 150px;
    margin-bottom: 10px;
    object-fit: contain;
  }
  .all-title {
    font-family: DFPKingGothicGB-Medium;
    font-size: 16px;
    font-weight: 500;
    color: #050404;
    letter-spacing: 0;
    margin-bottom: 10px;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .all-origin {
    font-family: DFPKingGothicGB-Regular;
    font-weight: 400;
    font-size: 10px;
    color: #999999;
    line-height: 16px;
    margin-top: 10px;
  }
  .all-type {
    position: absolute;
    right: 10px;
    top: 10px;
    font-family: DFPKingGothicGB-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
  }
  .all-time {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    line-height: 20px;
    margin-top: 10px;
  }
  .model-img {
    width: 200px;
    height: 150px;
    margin-top: 10px;
    object-fit: cover;
  }
  .model-tabs {
    margin-top: 20px;
    display: flex;
    .model-tabs-item {
      cursor: pointer;
      border: 1px solid #999999;
      padding: 4px 20px;
      font-family: DFPKingGothicGB-Regular;
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      line-height: 16px;
      margin-right: 10px;
    }
    .model-tabs-item__active {
      border: 1px solid #050404;
      color: #050404;
    }
  }
  .model-tabs-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    .model-tabs-content-list {
      width: 50%;
      font-family: DFPKingGothicGB-Regular;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 16px;
      margin-bottom: 10px;
      display: flex;
      .key {
        min-width: 60px;
        margin-right: 10px;
      }
      .val {
        font-family: PingFangSC-Medium;
        font-size: 12px;
        color: #000000;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
      }
    }
  }
  .series-list {
    margin-top: 20px;
    display: flex;
    overflow-x: auto;
    width: 100%;
    .series-list-item {
      cursor: pointer;
      min-width: fit-content;
      background: #FFFFFF;
      border: 1px solid #666666;
      font-family: DFPKingGothicGB-Regular;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 16px;
      padding: 4px 20px;
      margin-right: 10px;
    }
    .series-list-item__active {
      font-weight: 500;
      border: 1px solid #050404;
      color: #050404;
    }
  }
  .model-list {
    width: 100%;
    margin-top: 20px;
    .model-list-item {
      width: 100%;
      display: flex;
      margin-bottom: 15px;
      img {
        width: 120px;
        height: 80px;
        object-fit: cover;
      }
      .model-list-item-info {
        width: 100%;
        margin-left: 10px;
        .name {
          cursor: pointer;
          font-family: DFPKingGothicGB-Medium;
          font-weight: 500;
          font-size: 16px;
          color: #050404;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 180px;
        }
        .dealer {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 10px;
          font-family: DFPKingGothicGB-Medium;
          font-weight: 500;
          font-size: 14px;
          color: #050404;
          line-height: 16px;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .time {
          margin-top: 10px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 20px;
        }
      }
    }
    .model-more {
      font-family: DFPKingGothicGB-Regular;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      letter-spacing: 0;
      text-align: center;
      position: absolute;
      bottom: 10px;
      width: 100%;
    }
  }
  .all-text {
    position: absolute;
    top: 0;
    right: 0;
    font-family: DFPKingGothicGB-Regular;
    font-size: 14px;
    color: #666666;
    letter-spacing: 0;
    line-height: 21px;
  }
  .all-text-all {
    top: 10px;
    right: 10px;
  }
  .all-more {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    font-family: DFPKingGothicGB-Regular;
    font-size: 14px;
    color: #666666;
    letter-spacing: 0;
    line-height: 21px;
    .all-more-icon {
      width: 24px;
      height: 24px;
    }
  }
  .all-share {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    bottom: 4px;
    font-family: DFPKingGothicGB-Regular;
    font-weight: 400;
    font-size: 13px;
    color: #040404;
    letter-spacing: 0;
    line-height: 18px;
    .icon {
      width: 18px;
      height: 15px;
      margin-right: 7px;
    }
  }
  .all-share-icon {
    position: absolute;
    right: 0;
    bottom: 4px;
    width: 18px;
    height: 15px;
    margin-right: 7px;
  }
  .all-atlas-list {
    .atlas-item {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #DDDDDD;
      padding: 6px;
      margin-bottom: 10px;
      img {
        position: absolute;
        width: 20px;
        height: 20px;
        right: -30px;
      }
      .key {
        font-family: DFPKingGothicGB-Regular;
        font-size: 14px;
        color: #666666;
        letter-spacing: 0;
        line-height: 21px;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .value {
        font-family: DFPKingGothicGB-Regular;
        font-size: 14px;
        color: #666666;
        letter-spacing: 0;
        line-height: 21px;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .atlas-item:hover {
      border: 1px solid #3975C6;
    }
  }
  .all-atlas-more {
    cursor: pointer;
    text-align: center;
    font-family: DFPKingGothicGB-Regular;
    font-size: 14px;
    color: #666666;
    letter-spacing: 0;
    line-height: 21px;
  }
  .all-video-item,
  .all-speech-item,
  .all-graphic-item,
  .all-img-item,
  .all-article-content {
    .van-radio {
      .van-icon {
        width: 16px;
        height: 16px;
        border-color: rgba(0, 0, 0, 0.5);
      }
      .van-radio__icon--checked .van-icon {
        background-color: #000;
        border-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .all-video-item,
  .all-img-item {
    .van-radio {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
  .all-speech-item,
  .all-graphic-item,
  .all-article-content {
    .van-radio {
      z-index: 100;
      margin-right: 10px;
    }
  }
  // .all-video::-webkit-scrollbar {
  //   display: none;
  // }
  .all-video {
    display: flex;
    width: 100%;
    overflow: scroll;
    .all-video-item {
      cursor: pointer;
      position: relative;
      box-sizing: border-box;
      width: 200px;
      margin-right: 10px;
      .all-video-item-img {
        width: 200px;
        height: 150px;
      }
      .all-video-item-play {
        position: absolute;
        top: 55px;
        left: 80px;
        width: 40px;
        height: 40px;
      }
      .all-video-item-title {
        max-width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: DFPKingGothicGB-Regular;
        font-weight: 400;
        font-size: 12px;
        color: #050404;
        line-height: 21px;
      }
    }
  }
  .all-speech-item {
    display: flex;
    align-items: center;
    position: relative;
    .all-speech-content {
      font-family: DFPKingGothicGB-Regular;
      font-size: 14px;
      color: #666666;
      letter-spacing: 0;
      line-height: 21px;
      margin-bottom: 15px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
    .all-speech-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: DFPKingGothicGB-Regular;
        font-weight: 400;
        font-size: 13px;
        color: #040404;
        letter-spacing: 0;
        line-height: 18px;
        .btn-icon {
          width: 18px;
          height: 15px;
          margin-right: 5px;
          margin-left: 15px;
        }
      }
    }
  }
  .all-graphic-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    .all-graphic-content {
      height: 60px;
      font-family: DFPKingGothicGB-Regular;
      font-size: 14px;
      color: #666666;
      letter-spacing: 0;
      line-height: 21px;
      max-width: 60%;
      min-height: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .all-graphic-desc {
      position: relative;
      margin-top: 10px;
      font-family: DFPKingGothicGB-Regular;
      font-size: 10px;
      color: #999999;
      line-height: 21px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .all-graphic-img {
      background: red;
      width: 120px;
      height: 80px;
      position: absolute;
      right: 0;
      top: 30px;
    }
    .all-graphic-img-all {
      right: 10px;
      top: 40px;
    }
  }
  .all-img {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .all-img-item {
      cursor: pointer;
      box-sizing: border-box;
      width: calc((100% - 10px) / 2);
      margin-bottom: 10px;
      position: relative;
      .all-img-item-img {
        width: 100%;
        height: 118px;
      }
      .all-img-item-title {
        max-width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: DFPKingGothicGB-Regular;
        font-weight: 400;
        font-size: 12px;
        color: #050404;
        line-height: 21px;
      }
    }
  }
  .all-article {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .all-article-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .all-article-content-icon {
        width: 24px;
        height: 24px;
        margin-right: 15px;
      }
      .all-article-content-title {
        font-family: DFPKingGothicGB-Medium;
        font-weight: Medium;
        font-size: 16px;
        color: #040404;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
      }
    }
    .all-article-share {
      cursor: pointer;
      width: 18px;
      height: 15px;
    }
  }
}
.dealer-list {
  max-height: 200px;
  padding: 44px;
  overflow: auto;
  .dealer-list-item {
    cursor: pointer;
    font-family: DFPKingGothicGB-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #050404;
    text-align: center;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
</style>
