<template>
  <div class="cardList-file">
    <div class="cardList-file-item">
      <van-checkbox
        v-if="isCheckOpen"
        :name="item.cardData.id"
        shape="square"
        checked-color="#000"
      ></van-checkbox>
      <van-radio v-if="feedBackCheckOpen" :name="index"></van-radio>
      <div
        class="file"
        :class="isCheckOpen || feedBackCheckOpen ? 'file-294' : 'file-325'"
        @click="
          false &&
            !isCheckOpen && !feedBackCheckOpen &&
            toDetailPage('fileDetail', item, index)
        "
      >
        <div class="file-item-left">
          <!-- 差异：奥迪-文件类型；可复制化-固定icon -->
          <img
            v-if="proj !== 'audi'"
            class="file-icon"
            src="@/assets/img/vSearch/file_icon@2x.png"
            alt=""
          />
          <div
            class="name keywords-mark-f00 "
            v-html="proj === 'audi' ? `[${item.extension}] ${item.cardData.title}` : item.cardData.title"
          ></div>
        </div>
        <!-- 差异：奥迪-下载；可复制化-转发 -->
        <a
          v-if="proj === 'audi'"
          :href="[item.imageUrl]"
          :download="item.title"
        ><img src="@/assets/img/vSearch/download.png" alt="" class="width20" /></a>
        <img
          v-show="!isCheckOpen && proj !== 'audi'"
          class="file-item-right share-icon"
          src="@/assets/img/vSearch/icon_share.png"
          alt=""
          @click.stop="
            share('fileDetail', {
              ...item,
              offset:
                (searchParams.pageIndex - 1) * searchParams.pageSize +
                index +
                1
            })
          "
        />
      </div>
    </div>
    <CardListTags :tags="item.cardData.tags ? item.cardData.tags.split(',') : []" />
  </div>
</template>
<script>
import CardListTags from '@/components/CardList-tags.vue'

export default {
  name: 'SpeechArt',
  components: { CardListTags },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: () => {}
    },
    isCheckOpen: {
      type: Boolean,
      default: false
    },
    feedBackCheckOpen: {
      type: Boolean,
      default: false
    },
    searchParams: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      proj: process.env.VUE_APP_PROJ
    }
  },
  mounted() {
    if (document.querySelectorAll('#tags')) {
      document.querySelectorAll('#tags').forEach(item => {
        item.addEventListener(
          'touchmove',
          function(event) {
            event.stopPropagation()
          },
          true
        )
      })
    }
  },
  methods: {
    toDetailPage(pathName, item, index) {
      this.$emit('toDetailPage', pathName, item, index)
    },
    share(pathName, item) {
      this.$emit('share', pathName, item)
    }
  }
}
</script>
<style lang="less" scope>
.cardList-file {
  background: #fff;
  padding: 10px;
  margin-bottom: 5px;
  box-shadow: 0 0 4px 5px rgba(141, 141, 141, 0.05);
}
.cardList-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .width20{
    width:20px;
    padding: 0 5px;
  }
  .download{
    padding: 0 5px
  }
  .file {
    width: 100%;
    height: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .file-item-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: calc(100% - 30px);
    }
    .name {
      font-family: DFPKingGothicGB-Medium;
      font-weight: Medium;
      font-size: 16px;
      color: #040404;
      flex: 1;
      margin-right: 30px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .file-icon {
      width: 16px !important;
      height: 20px !important;
      margin-right: 15px;
    }
    .share-icon {
      width: 18px;
      height: 15px;
    }
  }
  .van-checkbox {
    .van-checkbox__icon {
      .van-icon {
        border-color: rgba(0, 0, 0, 0.5);
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
      .van-icon::before {
        font-size: 12px;
        position: relative;
        top: -2px;
      }
    }
    .van-checkbox__label {
      display: none;
    }
    .van-checkbox__icon--square {
      height: 16px;
    }
  }
  .van-radio {
    width: 16px;
    .van-radio__icon {
      .van-icon {
        border-color: rgba(0, 0, 0, 0.5);
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
      .van-icon::before {
        font-size: 12px;
        position: relative;
        top: -2px;
      }
    }
    .van-radio__icon--checked .van-icon {
      background-color: #000;
    }
    .van-radio__label {
      display: none;
    }
  }
  .file-294 {
    width: 90%;
    transition-duration: 0.1s;
  }
  .file-325 {
    width: 100%;
    transition-duration: 0.1s;
  }
}
</style>
