<template>
  <div class="cardList-picture">
    <div v-for="(item, index) in list" :key="index" class="cardList-picture-item">
      <van-checkbox
        v-if="isCheckOpen"
        :name="item.cardData.id"
        shape="square"
        checked-color="#000"
      ></van-checkbox>
      <van-radio v-if="feedBackCheckOpen" :name="index"></van-radio>
      <div
        @click="
          !isCheckOpen && !feedBackCheckOpen &&
          toDetailPage(topSearchMap.type === '3' ? 'graphicDetail' : 'posterDetail', item, index)
        "
      >
        <div v-if="proj === 'audi'" class="toplevel" >{{item.cardData.topStr}}</div>
        <img v-if="topSearchMap.type === '3' || topRecommendSearchMap.type === '3'" v-lazy="item.cardData.imageUrl" class="image-picture" :src="item.cardData.imageUrl" alt="" />
        <img v-if="topSearchMap.type === '9' || topSearchMap.type === '4'" v-lazy="item.cardData.imageUrl" class="image-poster" :src="item.cardData.imageUrl" alt="" />
        <div class="cardList-bottom">
          <div class="name keywords-mark-f00" v-html="item.cardData.title" />
          <img
            v-show="!isCheckOpen && !feedBackCheckOpen && (topSearchMap.type === '3' || topRecommendSearchMap.type==='3') && !notInQw"
            class="share-icon"
            :src="proj === 'audi' ? audiSendIcon : sendIcon"
            alt=""
            @click.stop="
              share(
                topSearchMap.type === '3'
                  ? 'graphicDetail'
                  : 'posterDetail',
                {
                  ...item,
                  offset:
                    (searchParams.pageIndex - 1) * searchParams.pageSize + index + 1
                }
              )
            "
          />
        </div>
      </div>
      <div style="padding: 0 10px">
        <CardListTags :tags="item.cardData.tags ? item.cardData.tags.split(',') : []" />
      </div>
    </div>
  </div>
</template>
<script>
import { getUrlParamsObj } from '@/scripts/utils'
import sendIcon from '@/assets/img/vSearch/icon_share.png'
import audiSendIcon from '@/assets/img/vSearch/send_icon.png'
import CardListTags from '@/components/CardList-tags.vue'

export default {
  name: 'Picture',
  components: { CardListTags },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    isCheckOpen: {
      type: Boolean,
      default: false
    },
    feedBackCheckOpen: {
      type: Boolean,
      default: false
    },
    searchParams: {
      type: Object,
      default: () => {}
    },
    topSearchMap: {
      type: Object,
      default: () => {}
    },
    topRecommendSearchMap: {
      type: Object,
      required: false,
      default: () => {
        return {
          keyWord: '',
          type: ''
        }
      }
    }
  },
  data() {
    return {
      sendIcon,
      audiSendIcon,
      notInQw: false,
      proj: process.env.VUE_APP_PROJ
    }
  },
  created() {
    if (this.topRecommendSearchMap !== null) {
      // this.topSearchMap.type = this.topRecommendSearchMap.type
      // this.topSearchMap.keyWord = this.topRecommendSearchMap.keyWord
    }
    this.notInQw = getUrlParamsObj().notInQw || ''
  },
  mounted() {
    if (document.querySelectorAll('#tags')) {
      document.querySelectorAll('#tags').forEach(item => {
        item.addEventListener(
          'touchmove',
          function(event) {
            event.stopPropagation()
          },
          true
        )
      })
    }
  },
  methods: {
    toDetailPage(pathName, item, index) {
      // 奥迪，推荐模块
      if (this.topSearchMap.type === '-1') {
        if (this.topRecommendSearchMap.type === '3') {
          pathName = 'graphicDetail'
        }
      }
      this.$emit('toDetailPage', pathName, item, index)
    },
    share(pathName, item) {
      this.$emit('share', pathName, item)
    }
  }
}
</script>
<style lang="less" scope>
.cardList-picture {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .toplevel{
    position:absolute;
    left:10px;top:10px;
    color:#ff9400;
    font-size:12px;font-weight:bolder;
  }
  .cardList-picture-item {
    position: relative;
    width: calc((100% - 10px) / 2);
    background: #ffffff;
    margin-bottom: 10px;
    .image-picture {
      width: 100%;
      height: 106px;
      object-fit: cover;
    }
    .image-poster {
      width: 100%;
      height: 182px;
      object-fit: cover;
    }
    .cardList-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      .name {
        font-family: DFPKingGothicGB-Regular;
        font-size: 12px;
        color: #050404;
        line-height: 21px;
        max-width: calc(100% - 40px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .share-icon {
        width: 18px;
        height: 15px;
      }
    }
  }
  .van-checkbox {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 9;
    .van-checkbox__icon {
      .van-icon {
        border-color: rgba(0, 0, 0, 0.5);
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
      .van-icon::before {
        font-size: 12px;
        position: relative;
        top: -2px;
      }
    }
    .van-checkbox__label {
      display: none;
    }
  }
  .van-radio {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 9;
    .van-radio__icon {
      .van-icon {
        border-color: rgba(0, 0, 0, 0.5);
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
      .van-icon::before {
        font-size: 12px;
        position: relative;
        top: -2px;
      }
    }
    .van-radio__icon--checked .van-icon {
      background-color: #000;
    }
    .van-radio__label {
      display: none;
    }
  }
}
</style>
