<template>
  <div class="cardList-activity">
    <van-radio v-if="feedBackCheckOpen" :name="index"></van-radio>
    <div
      class="activity"
      :class="feedBackCheckOpen ? 'activity-294' : 'activity-325'"
    >
      <div class="activity-item">
        <img v-if="item.imageUrl" class="activity-item-img" :src="item.imageUrl" alt=""/>
        <div v-if="getStatus(item.beginTime, item.endTime) === '已结束'" class="activity-end" />
        <div class="activity-item-info">
          <div class="name">{{ item.title }}</div>
          <div class="info">
            <span v-if="item.properties === '1'" class="text">线上活动 | </span>
            <span v-if="item.properties === '2'" class="text">线下活动 | </span>
            <span v-if="item.properties === '3'" class="text">线上+线下 | </span>
            <span>活动时间: {{ getTime(item.beginTime) }} 至 {{ getTime(item.endTime) }}</span>
          </div>
        </div>
        <div class="activity-item-status">{{ getStatus(item.beginTime, item.endTime) }}</div>
      </div>
      <CardListTags :tags="item.tags ? item.tags.split(',') : []" />
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import CardListTags from '@/components/CardList-tags.vue'

export default {
  name: 'SpeechArt',
  components: { CardListTags },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: () => {}
    },
    feedBackCheckOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      proj: process.env.VUE_APP_PROJ
    }
  },
  methods: {
    getTime(updateTime) {
      return updateTime ? moment(+updateTime).format('YYYY-MM-DD') : '/'
    },
    getStatus(beginTime, endTime) {
      const current = new Date().getTime()
      if (current > endTime) {
        return '已结束'
      }
      else if (current < beginTime) {
        return '未开始'
      }
      else {
        return '进行中'
      }
    }
  }
}
</script>
<style lang="less" scope>
.cardList-activity {
  position: relative;
  cursor: pointer;
  background: #fff;
  // padding: 10px;
  margin-bottom: 5px;
  box-shadow: 0 0 4px 5px rgba(141, 141, 141, 0.05);
  .activity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .activity-item {
      position: relative;
      width: 100%;
      height: 230px;
      .activity-item-img {
        width: 100%;
        height: 230px;
        object-fit: fill;
      }
      .activity-end {
        width: 100%;
        height: 230px;
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        background: rgba(0,0,0,0.50);
      }
      .activity-item-info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 15px;
        background: rgba(57, 117, 198, 0.5);
        .name {
          font-family: DFPKingGothicGB-Medium;
          font-weight: 500;
          font-size: 16px;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 24px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .info {
          font-family: DFPKingGothicGB-Medium;
          font-weight: 500;
          font-size: 12px;
          color: #FFFFFF;
          letter-spacing: 0;
          margin-top: 10px;
        }
      }
      .activity-item-status {
        position: absolute;
        top: 10px;
        right: 10px;
        background: #3975C6;
        font-family: DFPKingGothicGB-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        padding: 2px 5px;
      }
    }
  }
  .van-radio {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 9;
    width: 16px;
    .van-radio__icon {
      .van-icon {
        border-color: rgba(0, 0, 0, 0.5);
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
      .van-icon::before {
        font-size: 12px;
        position: relative;
        top: -2px;
      }
    }
    .van-radio__icon--checked .van-icon {
      background-color: #000;
    }
    .van-radio__label {
      display: none;
    }
  }
  .activity-294 {
    width: 90%;
    transition-duration: 0.1s;
  }
  .activity-325 {
    width: 100%;
    transition-duration: 0.1s;
  }
}
</style>
