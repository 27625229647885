<template>
  <div class="cardList-speechArt">
    <van-radio v-if="feedBackCheckOpen" :name="index" />
    <div class="speech-art" :style="feedBackCheckOpen ? 'width: 90%' : 'width: 100%'">
      <div v-if="proj === 'audi'" class="toplevel">{{item.cardData.topStr}}</div>
      <div v-if="proj !== 'audi'" class="name keywords-mark-f00" v-html="item.cardData.title" />
      <div v-if="proj === 'audi'" class="name keywords-mark-f00" v-html="'【' + item.cardData.orgName + '】' + item.cardData.title" />
      <div class="content keywords-mark-f00" v-html="item.cardData.content" />
      <CardListTags :tags="item.cardData.tags ? item.cardData.tags.split(',') : []" />
      <div class="btn">
        <div
          v-if="+item.cardData.hasLinkMaterial === 1"
          class="btn1"
          @click="toDetailPage('speechArtDetail', item, index)"
        >
          <img class="icon_f00" src="@/assets/img/vSearch/matter@2x.png" alt=""/>
          关联素材
        </div>
        <div
          class="btn2"
          @click="
            openPopupForm({
              ...item,
              offset: (searchParams.pageIndex - 1) * searchParams.pageSize + index + 1
            })
          "
        >
          <img class="icon_f00" :src="proj === 'audi' ? audiSendIcon : sendIcon" alt="" />
          发送
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sendIcon from '@/assets/img/vSearch/share_forward@2x.png'
import audiSendIcon from '@/assets/img/vSearch/send_icon.png'
import CardListTags from '@/components/CardList-tags.vue'

export default {
  name: 'SpeechArt',
  components: { CardListTags },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: () => {}
    },
    feedBackCheckOpen: {
      type: Boolean,
      default: false
    },
    searchParams: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      sendIcon,
      audiSendIcon,
      proj: process.env.VUE_APP_PROJ
    }
  },
  mounted() {
    if (document.querySelectorAll('#tags')) {
      document.querySelectorAll('#tags').forEach(item => {
        item.addEventListener(
          'touchmove',
          function(event) {
            event.stopPropagation()
          },
          true
        )
      })
    }
  },
  methods: {
    toDetailPage(pathName, item, index) {
      this.$emit('toDetailPage', pathName, item, index)
    },
    openPopupForm(item) {
      this.$emit('openPopupForm', item)
    }
  }
}
</script>
<style lang="less" scope>
.cardList-speechArt {
  width: 100%;
  display: flex;
  align-items: center;
  position:relative;
  .toplevel{
    position:absolute;
    right:0px;top:0px;
    color:#ff9400;
    font-size:12px;font-weight:bolder;
  }
  .van-radio {
    width: 16px;
    box-sizing: border-box;
    margin-right: 16px;
    .van-radio__icon {
      .van-icon {
        border-color: rgba(0, 0, 0, 0.5);
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
      .van-icon::before {
        font-size: 12px;
        position: relative;
        top: -2px;
      }
    }
    .van-radio__icon--checked .van-icon {
      background-color: #000;
    }
    .van-radio__label {
      display: none;
    }
  }
  .speech-art {
    .name {
      font-family: DFPKingGothicGB-Medium;
      font-size: 16px;
      color: #050404;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 10px;
    }
    .content {
      font-family: DFPKingGothicGB-Regular;
      font-size: 14px;
      color: #666666;
      letter-spacing: 0;
      line-height: 21px;
      margin-bottom: 15px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
    .btn {
      display: flex;
      justify-content: flex-end;
      font-family: DFPKingGothicGB-Medium;
      color: #000000;
      letter-spacing: 0;
      line-height: 21px;
      div {
        margin-left: 30px;
        font-size: 16px;
        position: relative;
      }
      .btn2 {
        margin-left: 42px;
      }
      .icon_f00 {
        width: 18px;
        height: 15px;
        position: absolute;
        left: -24px;
        top: 0;
      }
    }
  }
}
</style>
