<template>
  <div class="popup-filter">
    <van-action-sheet
      v-model="curIsShow"
      :round="false"
      title="筛选"
      @close="close"
    >
      <div class="content">
        <div v-for="(filterItem, index) in filterItemArr" :key="index" class="filter-item">
          <!-- && filterItem.data.length > filterItem.collapseLength 不知道这个 >9 是干嘛的   -->
          <van-collapse
            v-if="filterItem.data && filterItem.data.length"
            v-model="activeNames"
            :border="false"
          >
            <van-collapse-item
              :title="filterItem.label"
              :name="filterItem.label"
              :border="false"
            >
              <div v-if="filterItem.type === 'radio'" class="select-item-wrapper">
                <van-radio-group
                  v-model="curFilterFieldMap[filterItem.field]"
                  direction="horizontal"
                  @change="fieldValueChange(index, curFilterFieldMap[filterItem.field])"
                >
                  <van-radio
                    v-for="(item2, index2) in filterItem.data"
                    :key="index2"
                    :name="item2.value"
                    class="select-item"
                    >{{ item2.name }}</van-radio
                  >
                  <span
                    v-show="filterItem.data.length === 0"
                    class="select-item-empty"
                    >暂无</span
                  >
                </van-radio-group>
              </div>
              <div v-if="filterItem.type === 'checkbox'" class="select-item-wrapper">
                <van-checkbox-group
                  v-model="curFilterFieldMap[filterItem.field]"
                  direction="horizontal"
                  @change="fieldValueChange(index, curFilterFieldMap[filterItem.field])"
                >
                  <van-checkbox
                    v-for="(item2, index2) in filterItem.data"
                    :key="index2"
                    :name="item2.value"
                    class="select-item"
                    >{{ item2.name }}</van-checkbox
                  >
                  <span
                    v-show="filterItem.data.length === 0"
                    class="select-item-empty"
                    >暂无</span
                  >
                </van-checkbox-group>
              </div>
              <div v-if="filterItem.type === 'timeRange'" class="select-item-wrapper">
                <div class="time-input-wrapper">
                  <van-cell
                    :title="'请选择'"
                    :value="curFilterFieldMap[filterItem.field]"
                    @click="show = true"
                  />
                  <van-calendar
                    v-model="show"
                    :min-date="new Date(2010, 0, 1)"
                    :max-date="new Date()"
                    type="range"
                    :show-confirm="false"
                    allow-same-day
                    @confirm="date => onConfirm(index, date, filterItem.field)"
                    @opened="calendarOpened"
                  />
                  <van-icon
                    v-show="!!curFilterFieldMap[filterItem.field]"
                    name="cross"
                    class="clear-btn"
                    @click="onConfirm(index, [], filterItem.field)"
                  />
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
          <div v-else>
            <div class="label">{{ filterItem.label }}</div>
            <div v-if="filterItem.type === 'radio'" class="select-item-wrapper">
              <van-radio-group
                v-model="curFilterFieldMap[filterItem.field]"
                direction="horizontal"
                @change="fieldValueChange(index, curFilterFieldMap[filterItem.field])"
              >
                <van-radio
                  v-for="(item2, index2) in filterItem.data"
                  :key="index2"
                  :name="item2.value"
                  class="select-item"
                  >{{ item2.name }}</van-radio
                >
                <span
                  v-show="filterItem.data.length === 0"
                  class="select-item-empty"
                  >暂无</span
                >
              </van-radio-group>
            </div>
            <div
              v-if="filterItem.type === 'checkbox' && proj !== 'audi'"
              class="select-item-wrapper"
            >
              <van-checkbox-group
                v-if="proj !== 'audi'"
                :ref="`checkboxGroup${index}`"
                v-model="curFilterFieldMap[filterItem.field]"
                direction="horizontal"
                @change="fieldValueChange(index, curFilterFieldMap[filterItem.field])"
              >
                <van-checkbox
                  v-for="(item2, index2) in filterItem.data"
                  v-show="filterItem.data[0] && filterItem.data.length > 0"
                  :key="index2"
                  :name="item2.value"
                  class="select-item"
                  @click="
                    curVal =>
                      selAllClick(
                        index2 === 0 && item2.name === '全部',
                        index,
                        curFilterFieldMap[filterItem.field],
                        filterItem.data[0] && filterItem.data[0].name === '全部'
                          ? filterItem.data.length - 1
                          : filterItem.data.length,
                        filterItem.field
                      )
                  "
                  >{{ item2.name }}</van-checkbox
                >
              </van-checkbox-group>
              <van-checkbox-group
                v-if="proj === 'audi'"
                v-model="curFilterFieldMap[filterItem.field]"
                direction="horizontal"
                @change="fieldValueChange(index, curFilterFieldMap[filterItem.field])"
              >
                <van-checkbox
                  v-for="(item2, index2) in filterItem.data"
                  :key="index2"
                  :name="item2.value"
                  class="select-item"
                  >{{ item2.name }}</van-checkbox
                >
                <span
                  v-show="filterItem.data.length === 0"
                  class="select-item-empty"
                  >暂无</span
                >
              </van-checkbox-group>
            </div>
            <div v-if="filterItem.type === 'timeRange'" class="select-item-wrapper">
              <div class="time-input-wrapper">
                <van-cell
                  :title="'请选择'"
                  :value="curFilterFieldMap[filterItem.field]"
                  @click="show = true"
                />
                <van-calendar
                  v-model="show"
                  :min-date="new Date(2010, 0, 1)"
                  :max-date="new Date()"
                  type="range"
                  :show-confirm="false"
                  allow-same-day
                  @confirm="date => onConfirm(index, date, filterItem.field)"
                  @opened="calendarOpened"
                />
                <van-icon
                  v-show="!!curFilterFieldMap[filterItem.field]"
                  name="cross"
                  class="clear-btn"
                  @click="onConfirm(index, [], filterItem.field)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btns">
        <van-button type="default" @click="reset">重置</van-button>
        <van-button color="#000" @click="sure">确定</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  name: 'PopupFilter',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    filterFieldMap: {
      type: Object,
      default: () => {}
    },
    filterItemArr: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      proj: process.env.VUE_APP_PROJ,
      curIsShow: false,
      curFilterFieldMap: {},
      keyWord: '',
      type: '',
      date: '',
      show: false,
      activeNames: []
    }
  },
  watch: {
    isShow(val) {
      this.curIsShow = this.isShow
    },
    filterFieldMap: {
      handler(val) {
        console.log('val')
        console.log(val)
        this.curFilterFieldMap = { ...this.filterFieldMap }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.curIsShow = this.isShow
    this.curFilterFieldMap = { ...this.filterFieldMap }
  },
  methods: {
    calendarOpened() {
      this.$nextTick(() => {
        document.getElementsByClassName('van-calendar__body')[0].scrollTop -= 3
      })
    },
    // 点击全部时进行全选或全部清空,点击非全部时控制全部按钮选中
    selAllClick(isClickAll, index, resultList, filterItemDataLength, field) {
      console.log(isClickAll, index, resultList, filterItemDataLength, field)
      if (isClickAll) {
        const selectedAll = resultList.filter(item => item === 'ALL').length
        if (selectedAll > 0) {
          this.$refs[`checkboxGroup${index}`][0].toggleAll(true)
        }
        else {
          this.$refs[`checkboxGroup${index}`][0].toggleAll(false)
        }
      }
      else {
        if (resultList.indexOf('ALL') !== -1) {
          if (resultList.length - 1 === filterItemDataLength) {
            this.$refs[`checkboxGroup${index}`][0].toggleAll(true)
          }
          else {
            const result = []
            resultList.map(item => {
              if (item !== 'ALL') {
                result.push(item)
              }
            })
            this.curFilterFieldMap[field] = result
          }
        }
        else {
          if (resultList.length === filterItemDataLength) {
            this.$refs[`checkboxGroup${index}`][0].toggleAll(true)
          }
        }
      }
      this.$forceUpdate()
    },
    setFieldValue(objName, property, value) {
      this[objName][property] = value
    },
    formatDate(date) {
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var second = date.getSeconds()
      second = second < 10 ? '0' + second : second
      return y + '/' + m + '/' + d + ' ' + h + ':' + minute + ':' + second
      // return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} `
    },
    onConfirm(index, date, field) {
      if (date.length === 0) {
        this.curFilterFieldMap[field] = ''
        this.$emit('fieldValueChange', index, this.curFilterFieldMap[field])
      }
      else {
        const [start, end] = date
        const changeEnd = new Date(
          new Date(end).getTime() + (24 * 60 * 60 - 1) * 1000
        )
        console.log(changeEnd)
        this.show = false
        this.curFilterFieldMap[field] = `${this.formatDate(
          start
        )} 至 ${this.formatDate(changeEnd)}`
        this.$emit('fieldValueChange', index, this.curFilterFieldMap[field])
      }
    },
    search(val) {
      this.$emit('search', this.type, this.keyWord)
    },
    typeChange(val) {
      this.keyWord = ''
      this.$emit('search', this.type, '')
    },
    close() {
      this.$emit('close')
    },
    reset() {
      this.filterItemArr.map(item => {
        if (item.type === 'radio') {
          this.curFilterFieldMap[item.field] = ''
        }
        else if (item.type === 'checkbox') {
          this.curFilterFieldMap[item.field] = []
        }
        else if (item.type === 'timeRange') {
          this.curFilterFieldMap[item.field] = ''
        }
      })
    },
    sure() {
      this.$emit('sure', { ...this.curFilterFieldMap })
      this.$emit('close')
    },
    fieldValueChange(index, resultVal) {
      this.$emit('fieldValueChange', index, resultVal)
    }
  }
}
</script>

<style scoped lang="less">
.popup-filter {
  /deep/.van-action-sheet__header {
    padding: 0 15px;
    text-align: left;
    .van-icon {
      color: #050404;
    }
  }
  /deep/.van-action-sheet__content {
    padding: 15px;
    .content {
      padding-bottom: 100px;
      .filter-item {
        margin-bottom: 20px;
        .label {
          font-family: DFPKingGothicGB-Regular;
          font-size: 14px;
          color: #050404;
          text-align: left;
          line-height: 24px;
          margin-bottom: 15px;
        }
        .select-item-wrapper {
          .select-item {
            margin-bottom: 20px;
          }
          .select-item-empty {
            font-size: 12px;
            color: #999;
          }
          .van-radio-group {
            .van-radio {
              .van-radio__icon {
                display: none;
              }
              .van-radio__icon--checked + .van-radio__label {
                background: #000;
                color: #fff;
              }
              .van-radio__label {
                width: 95px;
                height: 36px;
                background: #f2f2f4;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #050404;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0;
              }
            }
          }
          .van-checkbox-group {
            .van-checkbox {
              .van-checkbox__icon {
                display: none;
              }
              .van-checkbox__icon--checked + .van-checkbox__label {
                background: #000;
                color: #fff;
              }
              .van-checkbox__label {
                height: 36px;
                background: #f2f2f4;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #050404;
                margin-left: 0;
                display: inline-block;
                line-height: 36px;
                text-align: center;
                padding: 0 14px;
                border-radius: 36px;
              }
            }
          }
        }
        .van-collapse {
          .van-collapse-item {
            .van-collapse-item__title {
              padding: 0;
              margin-bottom: 15px;
              .van-cell__right-icon::before {
                content: '展开';
                font-size: 10px;
                transform: rotate(0deg);
              }
              .van-cell__right-icon::after {
                content: '\e65e';
                font-size: 10px;
                transform: rotate(90deg) translateZ(0);
              }
            }
            .van-collapse-item__title--expanded {
              .van-cell__right-icon::before {
                content: '收起';
                font-size: 10px;
                transform: rotate(0deg);
              }
              .van-cell__right-icon::after {
                content: '\e65f';
                font-size: 10px;
                transform: rotate(90deg) translateZ(0);
              }
            }

            .van-collapse-item__wrapper {
              .van-collapse-item__content {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
  /deep/.btns {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    padding: 10px 0;
    right: 15px;
    width: 100%;
    background: #fff;
    .van-button {
      width: 90px;
      height: 32px;
      border: 1px solid #000000;
      margin-left: 10px;
      font-family: DFPKingGothicGB-Regular;
      font-size: 16px;
    }
  }
  /deep/.time-input-wrapper {
    border: 1px solid #999;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-right: 30px;
    position: relative;
    .van-cell {
      .van-cell__value {
        flex: 2;
      }
    }
    .clear-btn {
      transition: 0.2s;
      position: absolute;
      right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      font-size: 10px;
      color: #fff;
      background: #aaa;
    }
  }
}
</style>
