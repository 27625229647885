<template>
  <div v-if="tags.length" id="tags" class="item-labels">
    <div v-for="(item, i) in tags" :key="i" class="item-labels-item">
      <div class="label">{{ item }}</div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    tags: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
    }
  },
  watch: {},
  mounted() {
    if (document.querySelectorAll('#tags')) {
      document.querySelectorAll('#tags').forEach(item => {
        item.addEventListener(
          'touchmove',
          function(event) {
            event.stopPropagation()
          },
          true
        )
      })
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scope>
.item-labels {
  display: flex;
  margin-bottom: 10px;
  max-width: 100%;
  overflow-x: auto;
  .item-labels-item {
    box-sizing: border-box;
    margin-top: 10px;
    margin-right: 10px;
    padding: 2px 10px;
    background: rgba(79, 83, 88, 0.1);
    .label {
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #006EFF;
      max-width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
