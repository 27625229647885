var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cardList-picture"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"cardList-picture-item"},[(_vm.isCheckOpen)?_c('van-checkbox',{attrs:{"name":item.cardData.id,"shape":"square","checked-color":"#000"}}):_vm._e(),(_vm.feedBackCheckOpen)?_c('van-radio',{attrs:{"name":index}}):_vm._e(),_c('div',{on:{"click":function($event){!_vm.isCheckOpen && !_vm.feedBackCheckOpen &&
        _vm.toDetailPage(_vm.topSearchMap.type === '3' ? 'graphicDetail' : 'posterDetail', item, index)}}},[(_vm.proj === 'audi')?_c('div',{staticClass:"toplevel"},[_vm._v(_vm._s(item.cardData.topStr))]):_vm._e(),(_vm.topSearchMap.type === '3' || _vm.topRecommendSearchMap.type === '3')?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.cardData.imageUrl),expression:"item.cardData.imageUrl"}],staticClass:"image-picture",attrs:{"src":item.cardData.imageUrl,"alt":""}}):_vm._e(),(_vm.topSearchMap.type === '9' || _vm.topSearchMap.type === '4')?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.cardData.imageUrl),expression:"item.cardData.imageUrl"}],staticClass:"image-poster",attrs:{"src":item.cardData.imageUrl,"alt":""}}):_vm._e(),_c('div',{staticClass:"cardList-bottom"},[_c('div',{staticClass:"name keywords-mark-f00",domProps:{"innerHTML":_vm._s(item.cardData.title)}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCheckOpen && !_vm.feedBackCheckOpen && (_vm.topSearchMap.type === '3' || _vm.topRecommendSearchMap.type==='3') && !_vm.notInQw),expression:"!isCheckOpen && !feedBackCheckOpen && (topSearchMap.type === '3' || topRecommendSearchMap.type==='3') && !notInQw"}],staticClass:"share-icon",attrs:{"src":_vm.proj === 'audi' ? _vm.audiSendIcon : _vm.sendIcon,"alt":""},on:{"click":function($event){$event.stopPropagation();_vm.share(
              _vm.topSearchMap.type === '3'
                ? 'graphicDetail'
                : 'posterDetail',
              Object.assign({}, item,
                {offset:
                  (_vm.searchParams.pageIndex - 1) * _vm.searchParams.pageSize + index + 1})
            )}}})])]),_c('div',{staticStyle:{"padding":"0 10px"}},[_c('CardListTags',{attrs:{"tags":item.cardData.tags ? item.cardData.tags.split(',') : []}})],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }