<template>
  <div id="cardList" class="card-list">
    <div v-if="list.length" class="list-wrapper">
      <van-checkbox-group v-model="checkValList">
        <van-radio-group v-model="feedBackRadio" @change="feedBackVisible = feedBackRadio === 0 ? true : !!feedBackRadio">
        <div v-if="topSearchMap.type === '0'">
          <CardListAll
            :list="list"
            :top-search-map="topSearchMap"
            :feed-back-check-open="feedBackCheckOpen"
            @updateKeyword="updateKeyword"
            @allToDetailPage="allToDetailPage"
            @allOpenPopupForm="allOpenPopupForm"
            @typeChange="typeChange"
            @allShare="allShare"
          />
        </div>
        <div v-if="topSearchMap.type === '1' || (topSearchMap.type === '-1' && topRecommendSearchMap.type === '1')">
          <div v-for="(item, index) in list" :key="index" class="list-item">
            <SpeechArt
              :item="item"
              :index="index"
              :feed-back-check-open="feedBackCheckOpen"
              :search-params="searchParams"
              @toDetailPage="toDetailPage"
              @openPopupForm="openPopupForm"
            />
          </div>
        </div>
        <div v-if="topSearchMap.type === '2' || (topSearchMap.type === '-1' && topRecommendSearchMap.type === '2') ">
          <div v-for="(item, index) in list" :key="index" class="list-item">
            <Article
              :item="item"
              :index="index"
              :is-check-open="isCheckOpen"
              :feed-back-check-open="feedBackCheckOpen"
              :search-params="searchParams"
              @toDetailPage="toDetailPage"
              @share="share"
            />
          </div>
        </div>
        <div v-if="topSearchMap.type === '3' || (topSearchMap.type === '-1' && topRecommendSearchMap.type === '3')">
          <Picture
            :list="list"
            :is-check-open="isCheckOpen"
            :feed-back-check-open="feedBackCheckOpen"
            :top-search-map="topSearchMap"
            :top-recommend-search-map="topRecommendSearchMap"
            :search-params="searchParams"
            @toDetailPage="toDetailPage"
            @share="share"
          />
        </div>
        <div v-if="(topSearchMap.type === '9' || topSearchMap.type === '4') && !isInIframe">
          <Picture
            :list="list"
            :is-check-open="isCheckOpen"
            :feed-back-check-open="feedBackCheckOpen"
            :top-search-map="topSearchMap"
            :top-recommend-search-map="topRecommendSearchMap"
            :search-params="searchParams"
            @toDetailPage="toDetailPage"
            @share="share"
          />
        </div>
        <div v-if="(topSearchMap.type === '7' || topSearchMap.type === '5' || (topSearchMap.type === '-1' && topRecommendSearchMap.type === '5') ) && !doubleColShows">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="list-item-wrapper"
          >
            <Media
              :item="item"
              :index="index"
              :is-check-open="isCheckOpen"
              :double-col-shows="doubleColShows"
              :feed-back-check-open="feedBackCheckOpen"
              :search-params="searchParams"
              @toDetailPage="toDetailPage"
              @share="share"
            />
          </div>
        </div>
        <div v-if="(topSearchMap.type === '7' || topSearchMap.type === '5' ) && doubleColShows">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="list-item-wrapper list-item-wrapper-doubleCols"
          >
            <Media
              :item="item"
              :index="index"
              :is-check-open="isCheckOpen"
              :double-col-shows="doubleColShows"
              :feed-back-check-open="feedBackCheckOpen"
              :search-params="searchParams"
              @toDetailPage="toDetailPage"
              @share="share"
            />
          </div>
        </div>
        <div v-if="(topSearchMap.type === '8' || topSearchMap.type === '6') && !isInIframe">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="list-item-wrapper"
          >
            <File
              :item="item"
              :index="index"
              :is-check-open="isCheckOpen"
              :feed-back-check-open="feedBackCheckOpen"
              :search-params="searchParams"
              @toDetailPage="toDetailPage"
              @share="share"
            />
          </div>
        </div>
        <div v-if="topSearchMap.type === '10'">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="list-item-wrapper"
          >
            <Activity
              :item="item"
              :index="index"
              :feed-back-check-open="feedBackCheckOpen"
            />
          </div>
        </div>
        <div
          v-if="
            topSearchMap.type !== '0' &&
            list.length < pageDataTotalCount &&
              !loading &&
              emptyErrorType === ''
          "
          v-show="cardType !== '推荐'"
          class="load-more-page"
          @click="loadMorePage"
        >
          点击加载更多
        </div>
        <div
          v-if="
            topSearchMap.type === '0' &&
            topSearchMap.keyWord &&
            list.allList &&
            list.allList.length < pageDataTotalCount &&
              !loading &&
              emptyErrorType === ''
          "
          v-show="cardType !== '推荐'"
          class="load-more-page"
          @click="loadMorePage"
        >
          点击加载更多
        </div>
        </van-radio-group>
      </van-checkbox-group>
    </div>
    <EmptyError
      v-show="list.length === 0 && emptyErrorType != ''"
      :empty-error-type="emptyErrorType"
    />
    <popup-form
      ref="PopupForm"
      title="话术编辑"
      :form-field-map="formFieldMap"
      :form-item-arr="formItemArr"
      :is-show="popupFormShow"
      @sure="formSure"
      @close="popupFormShow = false"
    />
    <div
      v-if="hasFixedBtn && cardType !== '推荐'"
      class="fixed-btn feedback-btn"
      @click="feedBackCheck"
    >
      <img src="@/assets/img/vSearch/icon_feedback@2x.png" alt="" />
      <span>反馈</span>
    </div>
    <div
      v-if="
        list.length > 0 &&
          hasFixedBtn &&
          topSearchMap.type !== '0' &&
          topSearchMap.type !== '1' &&
          topSearchMap.type !== '9' &&
          topSearchMap.type !== '6' &&
          topSearchMap.type !== '10' &&
          topSearchMap.type !== '-1'
      "
      class="fixed-btn"
      @click="cardListCheck"
    >
      <img :src="isCheckOpen ? checkOpen : checkClose" alt="" />
      <span>多选</span>
    </div>
    <div
      v-show="
        list.length > 0 &&
          hasFixedBtn &&
          topSearchMap.type === '1' &&
          !isInIframe &&
          proj === 'audi'
      "
      class="fixed-btn"
      @click="$router.push({ name: 'userCenterHome' })"
    >
      <span>个人话术</span>
    </div>
    <div v-show="isCheckOpen" class="checked-send-btn">
      <van-button color="#020002" @click="multiShare">转发</van-button>
    </div>
    <van-popup
      v-model="feedBackVisible"
      position="bottom"
      :close-on-click-overlay="false"
    >
      <div class="feedBack-title">
        <div>问题反馈</div>
        <img class="close-icon" :src="feedBackClose" alt="" @click="closeFeedBack" />
      </div>
      <div class="feedBack-labels">
        <div class="label-title">问题类型</div>
        <div class="label-list">
          <div
            v-for="(item, i) in feedBackList"
            :key="i"
            :class="item.name === curCheck ? 'label-item-check' : 'label-item'"
            @click="curCheck = item.name"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="feedBack-content">
        <div class="content-title">其他</div>
        <van-form ref="feedForm">
          <van-field
            v-model="feedBack"
            placeholder="不超过150个字"
            maxlength="150"
            rows="2"
            autosize
            type="textarea"
            :rules="[
              { pattern: /^[\u4e00-\u9fa5A-Za-z0-9]*$/, message: '只支持汉字、数字、英文字母'}
            ]"
          />
        </van-form>
      </div>
      <div class="feedBack-btns">
        <van-button class="btn" type="default" @click="closeFeedBack">取消</van-button>
        <van-button class="btn btn-confirm" type="default" @click="topSearchMap.type === '0' ? allConfirmFeedBack() : confirmFeedBack()">确认</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import CardListAll from '@/components/CardList-all.vue'
import SpeechArt from '@/components/CardList-speechArt.vue'
import Article from '@/components/CardList-article.vue'
import Picture from '@/components/CardList-picture.vue'
import File from '@/components/CardList-file.vue'
import Activity from '@/components/CardList-activity.vue'
import Media from '@/components/CardList-media.vue'
import EmptyError from '@/components/EmptyError.vue'
import PopupForm from '@/components/PopupForm.vue'
import { speechArtDetail, feedBackList, addFeedBack } from '@/api/modules/vSearch'
import { Toast } from 'vant'
import { sendData } from '@/scripts/iframe'
import wxSDK from '@/scripts/wxsdk'
import { mapGetters } from 'vuex'
import checkClose from '@/assets/img/vSearch/check-close@2x.png'
import checkOpen from '@/assets/img/vSearch/check-open@2x.png'
import feedBackClose from '@/assets/img/vSearch/btn_close@2x.png'
import { v4 as uuidv4 } from 'uuid'
import audioBg from '@/assets/img/vSearch/audioBg.png'
import shareDefault from '@/assets/img/vSearch/share-default.png'
import { getUrlParamsObj } from '@/scripts/utils'
import { TABS_DATA } from '@/views/vSearch/constConfig'

export default {
  name: 'CardList',
  components: { CardListAll, SpeechArt, Article, Picture, File, Activity, Media, EmptyError, PopupForm },
  props: {
    topSearchMap: {
      type: Object,
      default: () => {
        return { type: '', keyWord: '' }
      } // list展示类型type: 1话术，2图文，3图片，4视频，5文件，6海报
    },
    topRecommendSearchMap: {
      type: Object,
      required: false,
      default: () => {
        return {
          keyWord: '',
          type: '',
          list: []
        }
      }
    },
    list: {
      type: Array,
      default: () => [] // 实际展示cardData中属性
    },
    pageDataTotalCount: {
      type: Number,
      default: 0 // 非分页展示情况pageDataTotalCount赋值为list长度
    },
    searchParams: {
      type: Object,
      default: () => {} // 用作发送和转发后埋点上报数据
    },
    loading: {
      type: Boolean,
      default: false // 控制加载更多按钮的展示
    },
    cardType: {
      type: String,
      default: () => {
        return ''
      } // 控制多媒体素材是否多列展示
    },
    doubleColShows: {
      type: Boolean,
      default: false // 控制多媒体素材是否多列展示
    },
    emptyErrorType: {
      type: String,
      default: ''
    },
    hasFixedBtn: {
      type: Boolean,
      default: false // 是否支持多选转发
    }
  },
  data() {
    return {
      proj: process.env.VUE_APP_PROJ,
      checkValList: [],
      formFieldMap: {
        sendContent: ''
      },
      formItemArr: [
        {
          field: 'sendContent',
          type: 'textarea',
          label: '发送至对话框'
        }
      ],
      popupFormShow: false,
      isCheckOpen: false,
      curCheck: '',
      feedBackRadio: '',
      feedBackVisible: false,
      feedBackCheckOpen: false,
      feedBackList: [],
      feedBack: '',
      audioBg,
      shareDefault,
      checkClose,
      checkOpen,
      feedBackClose,
      notInQw: '',
      curSendObj: {}
    }
  },
  computed: {
    ...mapGetters({
      authorizeInfo: 'userAuth/getAuthorizeInfo',
      userInfo: 'userAuth/getUsersCurrentInfo'
    }),
    itemWidth() {
      return 133 * 0.5 * (document.documentElement.clientWidth / 375)
    },
    gutterWidth() {
      return 10 * 0.5 * (document.documentElement.clientWidth / 375)
    },
    isInIframe() {
      return self !== top
    }
  },
  watch: {
    topSearchMap: {
      handler(now, old) {
        if (old && old.type !== now.type) {
          this.isCheckOpen = false
          this.checkValList = []
          this.feedBackVisible = false
          this.feedBackCheckOpen = false
          this.feedBackRadio = ''
          this.curCheck = ''
          this.feedBack = ''
        }
      },
      deep: true,
      immediate: true
    }
  },
  async created() {
    this.notInQw = getUrlParamsObj().notInQw || ''
    const res = await feedBackList()
    this.feedBackList = res.data
  },
  mounted() {
    if (document.querySelectorAll('#tags')) {
      document.querySelectorAll('#tags').forEach(item => {
        item.addEventListener(
          'touchmove',
          function(event) {
            event.stopPropagation()
          },
          true
        )
      })
    }
  },
  methods: {
    updateKeyword(keyWord) {
      this.$emit('updateKeyword', keyWord)
    },
    typeChange(type) {
      this.$emit('typeChange', { type, keyWord: this.topSearchMap.keyWord })
    },
    closeFeedBack() {
      this.feedBackVisible = false
      this.feedBackCheckOpen = false
      this.feedBackRadio = ''
      this.curCheck = ''
      this.feedBack = ''
      this.$refs.feedForm.resetValidation()
    },
    // 图片  多媒体  文章  列表页的发送按钮
    async share(pathName, item) {
      let materialDetailUrl = ''
      if (this.proj !== 'audi') {
        materialDetailUrl = `${location.protocol}//${location.host}/vSearch/${global.APP_CONFIG.tenantId}/${pathName}/${item.cardData.id}?id=${item.cardData.id}&notInQw=1`
      }
      if (this.proj === 'audi') {
        materialDetailUrl = `${location.protocol}//${location.host}/kml-h5/vSearch/${pathName}?id=${item.cardData.id}&notInQw=1`
      }
      if (self !== top) {
        const catId = TABS_DATA[process.env.VUE_APP_PROJ].filter(item => item.value === this.topSearchMap.type)[0].catId
        const data = {
          method: 'send',
          id: uuidv4(),
          data: {
            ...item,
            catId,
            imgUrl: item.videoCoverUrl,
            materialDetailUrl,
            trackData: {
              clickType: 2, // 1页面访问，2列表页转发，3详情页转发（话术编辑后转发、海报制作后转发),4搜索操作，5搜索页点开素材详情
              scene: self !== top ? 1 : 2, // 当前访问端，1web，2企微
              url: location.href, // 当前页面地址
              contentSource: 'knowledge', // 暂时写死knowledge
              redirectFrom: '', // 页面访问来源
              link: '', // 传播链,记录用户id
              searchLimit: '10', // 当前搜索结果限制数量
              matchType: 'FUZZY', // 当前搜索匹配类型,ACCURATE精准搜索，FUZZY模糊匹配
              query: JSON.stringify(this.searchParams), // 搜索接口参数对象
              sortType: 'SIMILARITY', // 当前搜索排序类型 DEFAULT默认排序,SIMILARITY相似度
              searchTimeStart: new Date().getTime(), // 用做页面访问时间点/转发时间点/搜索起始时间点
              searchTimeEnd: '', // 用做搜索结束时间点
              clickId: item.cardData.id, // 转发素材id
              clickTitle: this.filterLabels(item.title), // 转发素材标题
              contentType: [Number(this.topSearchMap.type)], // 1话术，2图文，3图片，4视频，5文件，6海报
              userOrgId: this.notInQw ? '' : this.userInfo.org && this.userInfo.org.length ? this.userInfo.org[0] : '', // 转发者所属部门----需要主部门ID
              userId: this.notInQw ? '' : this.userInfo.id || '',
              userName: this.notInQw ? '' : this.userInfo.userName || '', // 当前用户名称
              tenantId: this.notInQw ? '' : this.userInfo.tenant || '', // 当前用户名称
              fields: this.topSearchMap.keyWord ? [this.topSearchMap.keyWord] : [], // 当前搜索过滤字段
              offset: item.offset // 转发成功内容在总数据中第几条
            }
          }
        }
        if (this._isNetWorkOnLine()) {
          sendData(data)
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
      else {
        const contentJson = {
          title: this.filterLabels(item.title), // 分享标题
          desc: this.filterLabels(item.description ? item.description : ' '), // 分享描述
          link: materialDetailUrl, // 分享链接
          imgUrl: item.imageUrl || '' // 分享封面
        }
        if (this._isNetWorkOnLine()) {
          await wxSDK.sendChatMessage('news', contentJson)
          // const entry = await wxSDK.getContext()
          // console.log('entry>>>>>>', entry)
          // if (entry === 'single_kf_tools') {
          //   await wxSDK.sendChatMessage('news', contentJson)
          // }
          // else {
          //   await wxSDK.shareAppMessage(contentJson)
          // }
          console.log('企微中发送埋点数据')
          this._track({
            clickType: 2,
            query: JSON.stringify(this.searchParams),
            clickId: item.cardData.id,
            clickTitle: this.filterLabels(item.title),
            contentType: [Number(this.topSearchMap.type)],
            fields: this.topSearchMap.keyWord ? [this.topSearchMap.keyWord] : [],
            offset: item.offset
          })
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
      this.isCheckOpen = false
      this.feedBackCheckOpen = false
    },
    async allShare({ pathName, item, index }) {
      item.offset = (this.searchParams.pageIndex - 1) * this.searchParams.pageSize + index + 1
      let materialDetailUrl = ''
      if (this.proj !== 'audi') {
        materialDetailUrl = `${location.protocol}//${location.host}/vSearch/${global.APP_CONFIG.tenantId}/${pathName}/${item.materialId}?id=${item.materialId}&notInQw=1`
      }
      if (this.proj === 'audi') {
        materialDetailUrl = `${location.protocol}//${location.host}/kml-h5/vSearch/${pathName}?id=${item.materialId}&notInQw=1`
      }
      if (self !== top) {
        const catId = TABS_DATA[process.env.VUE_APP_PROJ].filter(tabItem => tabItem.value === item.materialType)[0].catId
        const data = {
          method: 'send',
          id: uuidv4(),
          data: {
            ...item,
            catId,
            id: item.materialId,
            desc: item.description,
            time: item.updateTime,
            imgUrl: item.videoCoverUrl,
            materialDetailUrl,
            trackData: {
              clickType: 2, // 1页面访问，2列表页转发，3详情页转发（话术编辑后转发、海报制作后转发),4搜索操作，5搜索页点开素材详情
              scene: self !== top ? 1 : 2, // 当前访问端，1web，2企微
              url: location.href, // 当前页面地址
              contentSource: 'knowledge', // 暂时写死knowledge
              redirectFrom: '', // 页面访问来源
              link: '', // 传播链,记录用户id
              searchLimit: '10', // 当前搜索结果限制数量
              matchType: 'FUZZY', // 当前搜索匹配类型,ACCURATE精准搜索，FUZZY模糊匹配
              query: JSON.stringify(this.searchParams), // 搜索接口参数对象
              sortType: 'SIMILARITY', // 当前搜索排序类型 DEFAULT默认排序,SIMILARITY相似度
              searchTimeStart: new Date().getTime(), // 用做页面访问时间点/转发时间点/搜索起始时间点
              searchTimeEnd: '', // 用做搜索结束时间点
              clickId: item.materialId, // 转发素材id
              clickTitle: this.filterLabels(item.title), // 转发素材标题
              contentType: [Number(this.topSearchMap.type)], // 1话术，2图文，3图片，4视频，5文件，6海报
              userOrgId: this.notInQw ? '' : this.userInfo.org && this.userInfo.org.length ? this.userInfo.org[0] : '', // 转发者所属部门----需要主部门ID
              userId: this.notInQw ? '' : this.userInfo.id || '',
              userName: this.notInQw ? '' : this.userInfo.userName || '', // 当前用户名称
              tenantId: this.notInQw ? '' : this.userInfo.tenant || '', // 当前用户名称
              fields: this.topSearchMap.keyWord ? [this.topSearchMap.keyWord] : [], // 当前搜索过滤字段
              offset: item.offset // 转发成功内容在总数据中第几条
            }
          }
        }
        if (this._isNetWorkOnLine()) {
          sendData(data)
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
      else {
        const contentJson = {
          title: this.filterLabels(item.title), // 分享标题
          desc: this.filterLabels(item.description ? item.description : ' '), // 分享描述
          link: materialDetailUrl, // 分享链接
          imgUrl: item.imageUrl || '' // 分享封面
        }
        if (this._isNetWorkOnLine()) {
          await wxSDK.sendChatMessage('news', contentJson)
          // const entry = await wxSDK.getContext()
          // console.log('entry>>>>>>', entry)
          // if (entry === 'single_kf_tools') {
          //   await wxSDK.sendChatMessage('news', contentJson)
          // }
          // else {
          //   await wxSDK.shareAppMessage(contentJson)
          // }
          console.log('企微中发送埋点数据')
          this._track({
            clickType: 2,
            query: JSON.stringify(this.searchParams),
            clickId: item.materialId,
            clickTitle: this.filterLabels(item.title),
            contentType: [Number(this.topSearchMap.type)],
            fields: this.topSearchMap.keyWord ? [this.topSearchMap.keyWord] : [],
            offset: item.offset
          })
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
      this.isCheckOpen = false
      this.feedBackCheckOpen = false
    },
    getDurationFormat(duration) {
      if (!duration) return '--:--'
      const second = duration % 60
      const minute = parseInt(duration / 60) % 60
      const hour = parseInt(duration / 60 / 60)
      return `${hour > 9 ? hour : '0' + String(hour)}:${
        minute > 9 ? minute : '0' + String(minute)
      }:${second > 9 ? second : '0' + String(second)}`
    },
    toDetailPage(pathName, item, index) {
      // if (this.$router.history.current.name === pathName) {
      //   let url = ''
      //   if (process.env.NODE_ENV === 'development') {
      //     url = `${location.protocol}//${location.host}/vSearch/${pathName}/${item.cardData.id}?id=${item.cardData.id}&offset=${(this.searchParams.pageIndex - 1) *
      //       this.searchParams.pageSize + index + 1}&keyWord=${this.topSearchMap.keyWord}&notInQw=${this.notInQw}&pageFrom=${
      //       this.$router.history.current.name === 'searchList' ? 'searchList' : ''
      //     }`
      //   }
      //   else if (this.proj !== 'audi') {
      //     url = `${location.protocol}//${location.host}/vSearch/${global.APP_CONFIG.tenantId}/${pathName}/${item.cardData.id}?id=${item.cardData.id}&offset=${(this.searchParams.pageIndex - 1) *
      //       this.searchParams.pageSize + index + 1}&keyWord=${this.topSearchMap.keyWord}&notInQw=${this.notInQw}&pageFrom=${
      //       this.$router.history.current.name === 'searchList' ? 'searchList' : ''
      //     }`
      //   }
      //   else {
      //     url = `${location.protocol}//${location.host}/kml-h5/vSearch/${pathName}/${item.cardData.id}?id=${item.cardData.id}&offset=${(this.searchParams.pageIndex - 1) *
      //       this.searchParams.pageSize + index + 1}&keyWord=${this.topSearchMap.keyWord}&notInQw=${this.notInQw}&pageFrom=${
      //       this.$router.history.current.name === 'searchList' ? 'searchList' : ''
      //     }`
      //   }
      //   location.href = url
      // }
      // else {
      //   this.$router.push({
      //     name: pathName === 'posterDetail' && this.proj !== 'audi' ? 'posterMake' : pathName,
      //     query: {
      //       id: item.cardData.id,
      //       offset: (this.searchParams.pageIndex - 1) * this.searchParams.pageSize + index + 1,
      //       keyWord: this.topSearchMap.keyWord,
      //       pageFrom: this.$router.history.current.name === 'searchList' ? 'searchList' : '',
      //       notInQw: this.notInQw,
      //       t: new Date().getTime()
      //     }
      //   })
      // }
      this.$router.push({
        name: pathName === 'posterDetail' && this.proj !== 'audi' ? 'posterMake' : pathName,
        query: {
          id: item.cardData.id,
          offset: (this.searchParams.pageIndex - 1) * this.searchParams.pageSize + index + 1,
          keyWord: this.topSearchMap.keyWord,
          pageFrom: this.$router.history.current.name === 'searchList' ? 'searchList' : '',
          notInQw: this.notInQw,
          t: new Date().getTime()
        }
      })
    },
    allToDetailPage({ pathName, item, index }) {
      console.log(this.$router.history.current.name, pathName)
      this.$router.push({
        name: pathName === 'posterDetail' && this.proj !== 'audi' ? 'posterMake' : pathName,
        query: {
          id: item.materialId,
          offset: (this.searchParams.pageIndex - 1) * this.searchParams.pageSize + index + 1,
          keyWord: this.topSearchMap.keyWord,
          pageFrom: this.$router.history.current.name === 'searchList' ? 'searchList' : '',
          notInQw: this.notInQw,
          t: new Date().getTime()
        }
      })
    },
    cardListCheck() {
      this.isCheckOpen = !this.isCheckOpen
      if (!this.isCheckOpen) {
        this.checkValList = []
      }
    },
    feedBackCheck() {
      if (this.list.length === 0) {
        this.feedBackVisible = true
      }
      this.feedBackCheckOpen = !this.feedBackCheckOpen
      if (!this.feedBackCheckOpen) {
        this.checkValList = []
        this.feedBackRadio = ''
      }
    },
    relation(id, index) {
      this.$router.push({
        name: 'speechArtDetail',
        query: {
          id,
          offset: (this.searchParams.pageIndex - 1) * this.searchParams.pageSize + index + 1,
          notInQw: this.notInQw,
          pageFrom: this.$router.history.current.name === 'searchList' ? 'searchList' : '',
          t: new Date().getTime()
        }
      })
    },
    openPopupForm(item) {
      this.curSendObj = JSON.parse(JSON.stringify(item))
      this.formFieldMap = {
        sendContent: this.curSendObj.cardData.content
      }
      this.popupFormShow = true
    },
    allOpenPopupForm({ item, index }) {
      item.offset = (this.searchParams.pageIndex - 1) * this.searchParams.pageSize + index + 1
      this.curSendObj = JSON.parse(JSON.stringify(item))
      this.formFieldMap = {
        sendContent: this.curSendObj.content
      }
      this.popupFormShow = true
    },
    formSure(obj) {
      console.log('xxxxx', this.curSendObj)
      console.log('xxxbbbb', obj)
      this.curSendObj.cardData = {}
      this.curSendObj.cardData.content = obj.sendContent
      this.curSendObj.content = obj.sendContent
      if (this.topSearchMap.type === '0') {
        this.allSend(this.curSendObj)
      }
      else {
        this.send(this.curSendObj)
      }
    },
    async allSend(item) {
      console.log('发送话术111', item)
      if (item.content === '' || this._trim(item.content) === '') {
        Toast.fail({
          message: '发送失败：内容不能为空',
          duration: 3 * 1000
        })
        return
      }
      let materialDetailUrl = ''
      if (this.proj !== 'audi') {
        materialDetailUrl = `${location.protocol}//${location.host}/vSearch/${global.APP_CONFIG.tenantId}/speechArtDetail/${item.materialId}?id=${item.materialId}&notInQw=1`
      }
      if (this.proj === 'audi') {
        materialDetailUrl = `${location.protocol}//${location.host}/kml-h5/vSearch/speechArtDetail?id=${item.materialId}&notInQw=1`
      }
      if (self !== top) {
        const res = await speechArtDetail({
          chatStratageId: item.materialId
        })
        res.data.content = item.content
        const catId = TABS_DATA[process.env.VUE_APP_PROJ].filter(tabItem => tabItem.value === item.materialType)[0].catId
        const data = {
          method: 'send',
          id: uuidv4(),
          data: {
            ...res.data,
            catId,
            materialDetailUrl,
            trackData: {
              clickType: 3, // 1页面访问，2列表页转发，3详情页转发（话术编辑后转发、海报制作后转发),4搜索操作，5搜索页点开素材详情
              scene: self !== top ? 1 : 2, // 当前访问端，1web，2企微
              url: location.href, // 当前页面地址
              contentSource: 'knowledge', // 暂时写死knowledge
              redirectFrom: '', // 页面访问来源
              link: '', // 传播链,记录用户id
              searchLimit: '10', // 当前搜索结果限制数量
              matchType: 'FUZZY', // 当前搜索匹配类型,ACCURATE精准搜索，FUZZY模糊匹配
              query: JSON.stringify(this.searchParams), // 搜索接口参数对象
              sortType: 'SIMILARITY', // 当前搜索排序类型 DEFAULT默认排序,SIMILARITY相似度
              searchTimeStart: new Date().getTime(), // 用做页面访问时间点/转发时间点/搜索起始时间点
              searchTimeEnd: '', // 用做搜索结束时间点
              clickId: item.materialId, // 转发素材id
              clickTitle: this.filterLabels(item.title), // 转发素材标题
              contentType: [Number(this.topSearchMap.type)], // 1话术，2图文，3图片，4视频，5文件，6海报
              userOrgId: this.notInQw ? '' : this.userInfo.org && this.userInfo.org.length ? this.userInfo.org[0] : '', // 转发者所属部门----需要主部门ID
              userId: this.notInQw ? '' : this.userInfo.id || '',
              userName: this.notInQw ? '' : this.userInfo.userName || '', // 当前用户名称
              tenantId: this.notInQw ? '' : this.userInfo.tenant || '', // 当前用户名称
              fields: this.topSearchMap.keyWord ? [this.topSearchMap.keyWord] : [], // 当前搜索过滤字段
              offset: item.offset // 转发成功内容在总数据中第几条
            }
          }
        }
        if (this._isNetWorkOnLine()) {
          sendData(data)
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
      else {
        const contentJson = {
          msgtype: 'text', // 消息类型，必填
          enterChat: true, // 为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
          text: {
            content: item.content // 文本内容
          },
          image: {
            mediaid: '' // 图片的素材id
          },
          video: {
            mediaid: '' // 视频的素材id
          },
          file: {
            mediaid: '' // 文件的素材id
          },
          news: {
            link: '', // H5消息页面url 必填
            title: '', // H5消息标题
            desc: '', // H5消息摘要
            imgUrl: '' // H5消息封面图片URL
          }
        }
        if (this._isNetWorkOnLine()) {
          await wxSDK.sendChatMessage('text', {
            content: contentJson.text.content
          })
          console.log('企微中发送埋点数据')
          this._track({
            clickType: 3,
            query: JSON.stringify(this.searchParams),
            clickId: item.materialId ? item.materialId : item.id,
            clickTitle: this.filterLabels(item.title),
            contentType: [Number(this.topSearchMap.type)],
            fields: this.topSearchMap.keyWord ? [this.topSearchMap.keyWord] : [],
            offset: item.offset
          })
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
      this.isCheckOpen = false
      this.feedBackCheckOpen = false
    },
    async send(item) {
      console.log('发送话术222', item)
      if (item.cardData.content === '' || this._trim(item.cardData.content) === '') {
        Toast.fail({
          message: '发送失败：内容不能为空',
          duration: 3 * 1000
        })
        return
      }
      let materialDetailUrl = ''
      if (this.proj !== 'audi') {
        materialDetailUrl = `${location.protocol}//${location.host}/vSearch/${global.APP_CONFIG.tenantId}/speechArtDetail/${item.cardData.id}?id=${item.cardData.id}&notInQw=1`
      }
      if (this.proj === 'audi') {
        materialDetailUrl = `${location.protocol}//${location.host}/kml-h5/vSearch/speechArtDetail?id=${item.cardData.id}&notInQw=1`
      }
      if (self !== top) {
        const res = await speechArtDetail({
          chatStratageId: item.materialId
        })
        res.data.content = item.cardData.content
        const catId = TABS_DATA[process.env.VUE_APP_PROJ].filter(item => item.value === this.topSearchMap.type)[0].catId
        const data = {
          method: 'send',
          id: uuidv4(),
          data: {
            ...res.data,
            catId,
            materialDetailUrl,
            trackData: {
              clickType: 3, // 1页面访问，2列表页转发，3详情页转发（话术编辑后转发、海报制作后转发),4搜索操作，5搜索页点开素材详情
              scene: self !== top ? 1 : 2, // 当前访问端，1web，2企微
              url: location.href, // 当前页面地址
              contentSource: 'knowledge', // 暂时写死knowledge
              redirectFrom: '', // 页面访问来源
              link: '', // 传播链,记录用户id
              searchLimit: '10', // 当前搜索结果限制数量
              matchType: 'FUZZY', // 当前搜索匹配类型,ACCURATE精准搜索，FUZZY模糊匹配
              query: JSON.stringify(this.searchParams), // 搜索接口参数对象
              sortType: 'SIMILARITY', // 当前搜索排序类型 DEFAULT默认排序,SIMILARITY相似度
              searchTimeStart: new Date().getTime(), // 用做页面访问时间点/转发时间点/搜索起始时间点
              searchTimeEnd: '', // 用做搜索结束时间点
              clickId: item.cardData.id, // 转发素材id
              clickTitle: this.filterLabels(item.title), // 转发素材标题
              contentType: [Number(this.topSearchMap.type)], // 1话术，2图文，3图片，4视频，5文件，6海报
              userOrgId: this.notInQw ? '' : this.userInfo.org && this.userInfo.org.length ? this.userInfo.org[0] : '', // 转发者所属部门----需要主部门ID
              userId: this.notInQw ? '' : this.userInfo.id || '',
              userName: this.notInQw ? '' : this.userInfo.userName || '', // 当前用户名称
              tenantId: this.notInQw ? '' : this.userInfo.tenant || '', // 当前用户名称
              fields: this.topSearchMap.keyWord ? [this.topSearchMap.keyWord] : [], // 当前搜索过滤字段
              offset: item.offset // 转发成功内容在总数据中第几条
            }
          }
        }
        if (this._isNetWorkOnLine()) {
          sendData(data)
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
      else {
        console.log('xxxxxxxx', item)
        const contentJson = {
          msgtype: 'text', // 消息类型，必填
          enterChat: true, // 为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
          text: {
            content: item.cardData.content // 文本内容
          },
          image: {
            mediaid: '' // 图片的素材id
          },
          video: {
            mediaid: '' // 视频的素材id
          },
          file: {
            mediaid: '' // 文件的素材id
          },
          news: {
            link: '', // H5消息页面url 必填
            title: '', // H5消息标题
            desc: '', // H5消息摘要
            imgUrl: '' // H5消息封面图片URL
          }
        }
        if (this._isNetWorkOnLine()) {
          await wxSDK.sendChatMessage('text', {
            content: contentJson.text.content
          })
          this._track({
            clickType: 3,
            query: JSON.stringify(this.searchParams),
            clickId: item.cardData.id ? item.cardData.id : item.materialId,
            clickTitle: this.filterLabels(item.title),
            contentType: [Number(this.topSearchMap.type)],
            fields: this.topSearchMap.keyWord ? [this.topSearchMap.keyWord] : [],
            offset: item.offset
          })
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
      this.isCheckOpen = false
      this.feedBackCheckOpen = false
    },
    async multiShare() {
      if (this.checkValList.length === 0) {
        Toast.fail({
          message: '请选择素材',
          duration: 2 * 1000
        })
        return
      }
      const checkedList = this.getCardListCheck()
      const offsetArr = this.getCardListCheckOffsetArr()
      console.log('多选转发素材:', this.checkValList, checkedList, offsetArr)
      const materialIdListJson = JSON.stringify(this.checkValList)
      const offsetArrJson = JSON.stringify(offsetArr)
      const userInfoJson = JSON.stringify({
        userName: this.userInfo.userName,
        avatar: this.userInfo.avatar,
        shareTime: new Date().getTime()
      })

      const materialType = this.topSearchMap.type
      let materialDetailUrl = ''
      if (this.proj !== 'audi') {
        materialDetailUrl = `${location.protocol}//${location.host}/vSearch/${global.APP_CONFIG.tenantId}/multiSelectList?materialIdListJson=${materialIdListJson}&userInfoJson=${userInfoJson}&materialType=${materialType}&offsetArrJson=${offsetArrJson}&notInQw=1`
      }
      if (this.proj === 'audi') {
        materialDetailUrl = `${location.protocol}//${location.host}/kml-h5/vSearch/multiSelectList?materialIdListJson=${materialIdListJson}&userInfoJson=${userInfoJson}&materialType=${materialType}&offsetArrJson=${offsetArrJson}&notInQw=1`
      }
      if (self !== top) {
        const item = {
          title: `${this.userInfo.userName}转发的内容`,
          imageUrl: checkedList[0].imageUrl
        }
        const catId = TABS_DATA[process.env.VUE_APP_PROJ].filter(item => item.value === this.topSearchMap.type)[0].catId
        const data = {
          method: 'send',
          id: uuidv4(),
          data: {
            ...item,
            catId,
            materialDetailUrl,
            trackData: {
              clickType: 3, // 1页面访问，2列表页转发，3详情页转发（话术编辑后转发、海报制作后转发),4搜索操作，5搜索页点开素材详情
              scene: self !== top ? 1 : 2, // 当前访问端，1web，2企微
              url: location.href, // 当前页面地址
              contentSource: 'knowledge', // 暂时写死knowledge
              redirectFrom: '', // 页面访问来源
              link: '', // 传播链,记录用户id
              searchLimit: '10', // 当前搜索结果限制数量
              matchType: 'FUZZY', // 当前搜索匹配类型,ACCURATE精准搜索，FUZZY模糊匹配
              query: JSON.stringify(this.searchParams), // 搜索接口参数对象
              sortType: 'SIMILARITY', // 当前搜索排序类型 DEFAULT默认排序,SIMILARITY相似度
              searchTimeStart: new Date().getTime(), // 用做页面访问时间点/转发时间点/搜索起始时间点
              searchTimeEnd: '', // 用做搜索结束时间点
              clickId: this.$route.params.id, // 转发素材id
              // clickTitle: this.filterLabels(item.title), // 转发素材标题
              contentType: [Number(this.topSearchMap.type)], // 1话术，2图文，3图片，4视频，5文件，6海报
              userOrgId: this.notInQw ? '' : this.userInfo.org && this.userInfo.org.length ? this.userInfo.org[0] : '', // 转发者所属部门----需要主部门ID
              userId: this.notInQw ? '' : this.userInfo.id || '',
              userName: this.notInQw ? '' : this.userInfo.userName || '', // 当前用户名称
              tenantId: this.notInQw ? '' : this.userInfo.tenant || '', // 当前用户名称
              fields: this.topSearchMap.keyWord ? [this.topSearchMap.keyWord] : [], // 当前搜索过滤字段
              offset: this.$route.query.offset // 转发成功内容在总数据中第几条
            }
          }
        }
        if (this._isNetWorkOnLine()) {
          sendData(data)
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
      else {
        const contentJson = {
          title: '' + `${this.userInfo.userName}转发的内容`, // 分享标题
          desc: '' + `${checkedList
            .map(
              (item, index) =>
                index + 1 + '.' + this.filterLabels(item.cardData.title)
            )
            .join(' ')}`, // 分享描述
          link: materialDetailUrl, // 分享链接
          imgUrl: checkedList[0].imageUrl || checkedList[0].cosPath || shareDefault // 分享封面
        }
        console.log('多选转发', contentJson)
        if (this._isNetWorkOnLine()) {
          await wxSDK.sendChatMessage('news', contentJson)
          // await wxSDK.shareAppMessage(contentJson)
          this.isCheckOpen = false
          this.feedBackCheckOpen = false
          // 企微内多选发送埋点处理: 发送成功后每条素材单独调用接口进行上报
          for (let index = 0; index < checkedList.length; index++) {
            console.log('企微中发送埋点数据')
            this._track({
              clickType: 2,
              query: JSON.stringify(this.searchParams),
              clickId: checkedList[index].cardData.id,
              clickTitle: this.filterLabels(checkedList[index].cardData.title),
              contentType: [Number(this.topSearchMap.type)],
              fields: [],
              offset: offsetArr[index]
            })
          }
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
    },
    loadMorePage() {
      this.$emit('loadMorePage')
    },
    getCardListCheck() {
      const checkedList = []
      this.checkValList.map(item => {
        this.list.map(item2 => {
          if (item === item2.cardData.id) {
            checkedList.push(item2)
          }
        })
      })
      return checkedList
    },
    getCardListCheckOffsetArr() {
      const offsetArr = []
      this.checkValList.map(item => {
        this.list.map((item2, index2) => {
          if (item === item2.cardData.id) {
            offsetArr.push(index2 + 1)
          }
        })
      })
      return offsetArr
    },
    cardListCheckSure() {
      this.$emit(
        'cardListCheckSure',
        this.checkValList,
        this.getCardListCheck()
      )
    },
    async confirmFeedBack() {
      console.log(this.feedBackRadio)
      this.$refs.feedForm.validate().then(async() => {
        const curFeedBackList = this.feedBackList.filter(item => item.name === this.curCheck)
        const curFeedBack = curFeedBackList.length > 0 ? curFeedBackList[0] : ''
        if (this.list.length === 0) {
          return Toast.fail({
            message: '请勾选要反馈的素材',
            duration: 3 * 1000
          })
        }
        if (!curFeedBack && !this.feedBack) {
          return Toast.fail({
            message: '请选择反馈内容',
            duration: 3 * 1000
          })
        }
        const index = this.feedBackRadio
        const item = this.list[index]
        const offset = (this.searchParams.pageIndex - 1) * this.searchParams.pageSize + index + 1
        const feedBackData = {
          clickId: item.cardData.id, // 转发素材id
          clickTitle: this.filterLabels(item.title), // 转发素材标题
          clickType: 8, // 1页面访问，2列表页转发，3详情页转发（话术编辑后转发、海报制作后转发),4搜索操作，5搜索页点开素材详情
          contentSource: 'knowledge', // 暂时写死knowledge
          contentType: [Number(item.cardData.materialType)],
          feedbackStatus: 1,
          feedbackTypeCode: curFeedBack.code,
          feedbackTypeDesc: curFeedBack.name,
          userFeedback: this.feedBack,
          fields: this.topSearchMap.keyWord ? [this.topSearchMap.keyWord] : [], // 当前搜索过滤字段
          id: item.cardData.id,
          link: '', // 传播链,记录用户id
          matchType: 'FUZZY', // 当前搜索匹配类型,ACCURATE精准搜索，FUZZY模糊匹配
          offset,
          query: JSON.stringify(this.searchParams), // 搜索接口参数对象
          redirectFrom: '', // 页面访问来源
          scene: self !== top ? 1 : 2, // 当前访问端，1web，2企微
          searchLimit: '10', // 当前搜索结果限制数量
          searchTimeEnd: '', // 用做搜索结束时间点
          searchTimeStart: new Date().getTime(), // 用做页面访问时间点/转发时间点/搜索起始时间点
          url: location.href, // 当前页面地址
          sortType: 'SIMILARITY', // 当前搜索排序类型 DEFAULT默认排序,SIMILARITY相似度
          userOrgName: this.notInQw ? '' : this.userInfo.orgName && this.userInfo.orgName.length ? this.userInfo.orgName[0] : '',
          userOrgId: this.notInQw ? '' : this.userInfo.org && this.userInfo.org.length ? this.userInfo.org[0] : '', // 转发者所属部门----需要主部门ID
          userId: this.notInQw ? '' : this.userInfo.id || '',
          userName: this.notInQw ? '' : this.userInfo.userName || '', // 当前用户名称
          tenantId: global.APP_CONFIG.tenantId
        }
        const res = await addFeedBack(feedBackData)
        if (res.code === 0) {
          Toast.success({
            message: '反馈成功',
            duration: 3 * 1000
          })
          this.closeFeedBack()
        }
      })
    },
    async allConfirmFeedBack() {
      console.log(this.feedBackRadio)
      this.$refs.feedForm.validate().then(async() => {
        const curFeedBackList = this.feedBackList.filter(item => item.name === this.curCheck)
        const curFeedBack = curFeedBackList.length > 0 ? curFeedBackList[0] : ''
        if (this.list.length === 0) {
          return Toast.fail({
            message: '请勾选要反馈的素材',
            duration: 3 * 1000
          })
        }
        if (!curFeedBack && !this.feedBack) {
          return Toast.fail({
            message: '请选择反馈内容',
            duration: 3 * 1000
          })
        }
        const index = this.feedBackRadio.index
        const item = this.feedBackRadio.item
        const offset = (this.searchParams.pageIndex - 1) * this.searchParams.pageSize + index + 1
        const feedBackData = {
          clickId: item.materialId, // 转发素材id
          clickTitle: this.filterLabels(item.title), // 转发素材标题
          clickType: 8, // 1页面访问，2列表页转发，3详情页转发（话术编辑后转发、海报制作后转发),4搜索操作，5搜索页点开素材详情
          contentSource: 'knowledge', // 暂时写死knowledge
          contentType: [Number(item.materialType)],
          feedbackStatus: 1,
          feedbackTypeCode: curFeedBack.code,
          feedbackTypeDesc: curFeedBack.name,
          userFeedback: this.feedBack,
          fields: this.topSearchMap.keyWord ? [this.topSearchMap.keyWord] : [], // 当前搜索过滤字段
          id: item.materialId,
          link: '', // 传播链,记录用户id
          matchType: 'FUZZY', // 当前搜索匹配类型,ACCURATE精准搜索，FUZZY模糊匹配
          offset,
          query: JSON.stringify(this.searchParams), // 搜索接口参数对象
          redirectFrom: '', // 页面访问来源
          scene: self !== top ? 1 : 2, // 当前访问端，1web，2企微
          searchLimit: '10', // 当前搜索结果限制数量
          searchTimeEnd: '', // 用做搜索结束时间点
          searchTimeStart: new Date().getTime(), // 用做页面访问时间点/转发时间点/搜索起始时间点
          url: location.href, // 当前页面地址
          sortType: 'SIMILARITY', // 当前搜索排序类型 DEFAULT默认排序,SIMILARITY相似度
          userOrgName: this.notInQw ? '' : this.userInfo.orgName && this.userInfo.orgName.length ? this.userInfo.orgName[0] : '',
          userOrgId: this.notInQw ? '' : this.userInfo.org && this.userInfo.org.length ? this.userInfo.org[0] : '', // 转发者所属部门----需要主部门ID
          userId: this.notInQw ? '' : this.userInfo.id || '',
          userName: this.notInQw ? '' : this.userInfo.userName || '', // 当前用户名称
          tenantId: global.APP_CONFIG.tenantId
        }
        const res = await addFeedBack(feedBackData)
        if (res.code === 0) {
          Toast.success({
            message: '反馈成功',
            duration: 3 * 1000
          })
          this.closeFeedBack()
        }
      })
    }
  }
}
</script>

<style lang="less" scope>
.card-list {
  background: #f5f7fa;
  .list-wrapper {
    padding: 15px 15px 52px 15px;
    .list-item-wrapper-doubleCols {
      display: inline-block;
      width: calc((100% - 10px) / 2);
    }
    .list-item-wrapper-doubleCols:nth-child(2n-1) {
      margin-right: 10px;
    }
    .list-item {
      cursor: pointer;
      background: #fff;
      padding: 10px;
      margin-bottom: 5px;
      box-shadow: 0 0 4px 5px rgba(141, 141, 141, 0.05);
    }
    .list-item-btns {
      display: flex;
      justify-content: flex-end;
      margin: 0 10px 15px 0;
    }
    .list-item-picture {
      width: 165px;
      display: inline-block;
      position: relative;
      padding: 0px !important;
      margin-bottom: 15px;
      .van-checkbox {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 9;
        .van-checkbox__icon {
          .van-icon {
            border-color: #000 !important;
            width: 16px;
            height: 16px;
            background: url('../assets/img/vSearch/selectBg.png') center
              no-repeat;
            background-size: 16px;
            line-height: 15px;
          }
          .van-icon::before {
            font-size: 12px;
            position: relative;
            top: -2px;
          }
        }
        .van-checkbox__label {
          display: none;
        }
      }
      .van-radio {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 9;
        .van-radio__icon {
          .van-icon {
            border-color: #000 !important;
            width: 16px;
            height: 16px;
            background: url('../assets/img/vSearch/selectBg.png') center
              no-repeat;
            background-size: 16px;
            line-height: 15px;
          }
          .van-icon::before {
            font-size: 12px;
            position: relative;
            top: -2px;
          }
        }
        .van-radio__icon--checked .van-icon {
          background-color: #000;
        }
        .van-radio__label {
          display: none;
        }
      }
    }
    .load-more-page {
      cursor: pointer;
      margin: 50px 0;
      text-align: center;
      font-size: 16px;
      color: #666;
    }
  }
  .fixed-btn {
    width: 50px;
    cursor: pointer;
    height: 50px;
    background: #020002;
    border-radius: 50%;
    position: fixed;
    bottom: 120px;
    right: 0;
    font-size: 12px;
    font-family: PingFangSC-Regular;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      transform: scale(0.8);
    }
    img {
      width: 20px;
      height: 20px;
      margin-bottom: 3px;
    }
  }
  .feedback-btn {
    bottom: 200px;
  }
  .checked-send-btn {
    cursor: pointer;
    width: 100%;
    height: 50px;
    background: #fff;
    z-index: 10;
    position: fixed;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .van-button {
      display: block;
      position: absolute;
      right: 11px;
      width: 90px;
      height: 32px;
      font-family: DFPKingGothicGB-Regular;
      font-size: 16px;
      border-radius: 0;
    }
  }
  .feedBack-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 18px 15px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    border-bottom: 1px solid rgba(0,0,0,0.10);
    .close-icon {
      width: 24px;
      height: 24px;
    }
  }
  .feedBack-labels {
    box-sizing: border-box;
    padding: 16px;
    border-bottom: 1px solid rgba(0,0,0,0.10);
    .label-title {
      margin-bottom: 12px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 16px;
      color: #000000;
    }
    .label-list {
      display: flex;
      justify-content: space-between;
      .label-item {
        cursor: pointer;
        box-sizing: border-box;
        padding: 6px 10px;
        border: 1px solid #D9D9D9;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
      }
      .label-item-check {
        cursor: pointer;
        box-sizing: border-box;
        padding: 6px 10px;
        border: 1px solid #D9D9D9;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        background: #333333;
      }
    }
  }
  .feedBack-content {
    box-sizing: border-box;
    padding: 16px;
    border-bottom: 5px solid rgba(0,0,0,0.10);
    .content-title {
      margin-bottom: 12px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 16px;
      color: #000000;
    }
    .van-cell {
      box-sizing: border-box;
      padding: 6px 10px;
      border: 1px solid #D9D9D9;
    }
  }
  .feedBack-btns {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 12px 16px;
    .btn {
      cursor: pointer;
      width: 166px;
      height: 46px;
      background: #F5F5F5;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-confirm {
      background: #333333;
      color: #FFFFFF;
    }
  }
}
</style>
