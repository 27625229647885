<template>
  <div class="cardList-article">
    <van-checkbox v-if="isCheckOpen" :name="item.cardData.id" shape="square" checked-color="#000" />
    <van-radio v-if="feedBackCheckOpen" :name="index" />
    <div
      class="cardList-article-item"
      :class="isCheckOpen || feedBackCheckOpen ? 'article-294' : 'article-325'"
      @click="!isCheckOpen && !feedBackCheckOpen && toDetailPage('articleDetail', item, index)"
    >
      <div class="toplevel" v-if="proj === 'audi'" >{{item.cardData.topStr}}</div>
      <div class="name keywords-mark-f00" v-html="item.cardData.title"></div>
      <div class="content">
        <div class="left">
          <div class="top keywords-mark-f00" v-html="item.cardData.desc || ''" />
        </div>
        <div class="right">
          <img
            v-lazy="item.cardData.imageUrl"
            :src="item.cardData.imageUrl"
            alt=""
          />
        </div>
      </div>
      <div class="card-bottom-wrapper">
        <div class="bottom">
          {{ item.cardData.orgName || '/' }}&nbsp;&nbsp;&nbsp;{{
            item.cardData.time
              ? timeTypeFomate(item.cardData.time)
              : '--'
          }}
        </div>
        <span
          v-show="!isCheckOpen"
          @click.stop="
            share('articleDetail', {
              ...item,
              offset:
                (searchParams.pageIndex - 1) * searchParams.pageSize +
                index +
                1
            })
          "
        >
          <img
            style="width: 18px;height: 15px;"
            class="share-icon"
            :src="proj === 'audi' ? audiSendIcon : sendIcon"
            alt=""
          />转发
        </span>
      </div>
      <CardListTags :tags="item.cardData.tags ? item.cardData.tags.split(',') : []" />
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import sendIcon from '@/assets/img/vSearch/icon_share.png'
import audiSendIcon from '@/assets/img/vSearch/send_icon.png'
import CardListTags from '@/components/CardList-tags.vue'

export default {
  name: 'Article',
  components: { CardListTags },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: () => {}
    },
    isCheckOpen: {
      type: Boolean,
      default: false
    },
    feedBackCheckOpen: {
      type: Boolean,
      default: false
    },
    searchParams: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      sendIcon,
      audiSendIcon,
      proj: process.env.VUE_APP_PROJ
    }
  },
  mounted() {
    if (document.querySelectorAll('#tags')) {
      document.querySelectorAll('#tags').forEach(item => {
        item.addEventListener(
          'touchmove',
          function(event) {
            event.stopPropagation()
          },
          true
        )
      })
    }
  },
  methods: {
    timeTypeFomate(date, type = 'YYYY-MM-DD HH:mm:ss') {
      if (new Date(date).getDate() === new Date().getDate()) {
        return '今日发布'
      }
      else if (
        new Date().getTime() - new Date(date).getTime() <=
        7 * 24 * 60 * 60 * 1000
      ) {
        const dayNum = Math.ceil(
          (new Date().getTime() - new Date(date).getTime()) /
            (24 * 60 * 60 * 1000)
        )
        console.log(dayNum)
        return `${dayNum}天前`
      }
      else {
        return moment(new Date(Number(date))).format(type)
      }
    },

    toDetailPage(pathName, item, index) {
      this.$emit('toDetailPage', pathName, item, index)
    },
    share(pathName, item) {
      this.$emit('share', pathName, item)
    }
  }
}
</script>
<style lang="less" scope>
.cardList-article {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .van-checkbox {
    width: 16px;
    .van-checkbox__icon {
      .van-icon {
        border-color: rgba(0, 0, 0, 0.5);
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
      .van-icon::before {
        font-size: 12px;
        position: relative;
        top: -2px;
      }
    }
    .van-checkbox__label {
      display: none;
    }
  }
  .van-radio {
    width: 16px;
    .van-radio__icon {
      .van-icon {
        border-color: rgba(0, 0, 0, 0.5);
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
      .van-icon::before {
        font-size: 12px;
        position: relative;
        top: -2px;
      }
    }
    .van-radio__icon--checked .van-icon {
      background-color: #000;
    }
    .van-radio__label {
      display: none;
    }
  }
  .cardList-article-item {
    position:relative;
    .toplevel{
      position:absolute;
      right:0px;top:0px;
      color:#ff9400;
      z-index:33;
      font-size:12px;font-weight:bolder;
    }
    .name {
      font-family: DFPKingGothicGB-Medium;
      font-size: 16px;
      color: #050404;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 10px;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        width: 60%;
        margin-right: 10px;
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top {
          font-family: DFPKingGothicGB-Regular;
          font-size: 14px;
          color: #666666;
          letter-spacing: 0;
          height: 63px;
          line-height: 21px;
          margin-bottom: 11px;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
      .right {
        width: 40%;
        height: 90px;
        background-size: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
    .card-bottom-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
      .bottom {
        font-family: DFPKingGothicGB-Regular;
        font-size: 10px;
        color: #999999;
        line-height: 16px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      span {
        opacity: 0.9;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 13px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        display: flex;
        align-items: center;
        margin-right: 8px;
        img {
          margin-right: 4px;
        }
      }
    }
  }
  .article-294 {
    width: 90%;
  }
  .article-325 {
    width: 100%;
  }
}
</style>
